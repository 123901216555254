@import '../../../assets/style/variables';
@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.contact-us {
	&__form-wrapper {
		flex: 1;
		max-width: 635px;
	}

	&__form {
		display: flex;
		flex-direction: column;

		&.white {
			.contact-us__form-checkbox__label {
				color: $color-main;

				a {
					color: $color-main;
				}
			}
		}

		&-body {
			display: grid;
			grid-template-columns: 1fr 1fr;
			column-gap: 24px;
			row-gap: 8px;
		}

		&-input {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: 72px;
		}

		&-textarea {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			grid-column: 1 / span 2;
			min-height: 167px;
		}

		.input-error-label {
			padding-left: 17px;

			span {
				color: $color-red;
				font-family: 'IBM';
				font-size: 0.75rem;
			}
		}

		.file-footnote {
			padding: 5px 0 0 0;
			font-family: 'IBM';
			font-size: 0.75rem;
			line-height: 140%;
			color: $color-white;
			opacity: 0.6;
			&.white {
				color: rgba(55, 59, 79, 1);
			}
			&--error {
				color: $color-red;
			}
		}

		&-checkbox {
			position: relative;
			margin-top: 1rem;
			margin-bottom: 8px;

			input {
				display: none;

				&:checked + .checkbox-element {
					border-color: $color-blue;
					background-color: $color-blue;
				}

				&:checked + .checkbox-element {
					&::after,
					&::before {
						background-color: $color-white;
					}
				}
			}

			&__label {
				position: relative;
				padding-left: 30px;
				font-family: 'IBM';
				font-size: 0.75rem;
				line-height: 140%;
				opacity: 0.7;
				color: $color-white;

				a {
					color: $color-white;
					text-decoration: underline;
					white-space: nowrap;
					margin: 0 5px;

					&:hover {
						color: $color-white;
						opacity: 0.6;
					}
				}
			}

			.checkbox-element {
				position: absolute;
				left: 0;
				top: 3px;
				width: 16px;
				height: 16px;
				border: 1px solid rgba(55, 59, 79, 0.4);
				border-radius: 2px;
				background-color: $color-white;
				cursor: pointer;
				transition: $transition;

				&::before {
					content: '';
					position: absolute;
					top: 6px;
					left: 4px;
					width: 2px;
					height: 6px;
					background-color: transparent;
					transform: rotate(-40deg);
					pointer-events: none;
					transition: $transition;
				}

				&::after {
					content: '';
					position: absolute;
					top: 3px;
					left: 8px;
					width: 2px;
					height: 9px;
					background-color: transparent;
					transform: rotate(40deg);
					pointer-events: none;
					transition: $transition;
				}
			}

			&-checkbox-label {
				position: relative;
				padding-left: 30px;
				font-family: 'IBM';
				font-size: 0.75rem;
				line-height: 140%;
				opacity: 0.7;
				color: $color-white;

				&-link {
					color: $color-white;
					text-decoration: underline;
					white-space: nowrap;
					margin: 0 5px;

					&:hover {
						color: $color-white;
						opacity: 0.6;
					}
				}
			}

			&.error {
				.contact-us__form-checkbox__label,
				.contact-us__form-checkbox__label a {
					color: $color-red;
				}
			}

			.input-error-label {
				padding-left: 30px;
			}
		}

		&-attach {
			font-family: 'IBM';
			width: 150px;
			height: 30px;
			margin-left: auto;
			padding-left: 23px;
			border: 1px solid rgba(55, 59, 79, 0.3);
			opacity: 0.5;
			background-size: 25px;
			font-style: normal;
			font-weight: bold;
			font-size: 0.75rem;
			line-height: 16px;
			color: $color-main;
			transition: $transition;

			@include bg-image('icons/attach-file.svg');

			&:hover {
				opacity: 1;
			}

			&.file-name {
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: $color-white;
				background-size: 7px;
				padding: 0 5px 0 30px;

				@include bg-image('icons/file.svg');

				p {
					white-space: nowrap;
				}
			}

			&.not-valid {
				border: 1px solid $color-red;
			}
		}

		.remove-file-icon {
			position: absolute;
			bottom: 50%;
			transform: translateY(-50%);
			right: 7px;
			width: 16px;
			height: 16px;
			border: none;
			border-radius: 50%;
			background-color: #4d5062;

			&:hover {
				opacity: 0.7;
			}

			&::after {
				content: '';
				position: absolute;
				top: 3px;
				left: 7px;
				height: 10px;
				width: 1.5px;
				background-color: $color-white;
				transform: rotate(45deg);
			}

			&::before {
				content: '';
				position: absolute;
				top: 3px;
				left: 7px;
				height: 10px;
				width: 1.5px;
				background-color: $color-white;
				transform: rotate(-45deg);
			}
		}
	}

	&__subform {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 8px;
		margin-bottom: 6px;
	}

	@media (max-width: 1200px) {
		&__form-input {
			max-width: 100%;
		}
	}
}

.hidden-input-file {
	display: none;
}

.file__reader {
	position: relative;

	display: flex;
	flex-direction: column;
	align-items: flex-end;

	span {
		font-family: 'IBM';
	}
}

@media (max-width: 767px) {
	.contact-us__form-body {
		column-gap: 16px;
		row-gap: 8px;
		margin-top: 50px;
	}
}

@media (max-width: 640px) {
	.contact-us__form {
		&-body {
			grid-template-columns: 1fr;
		}
		&-input {
			max-width: 100%;
		}
		&-checkbox__label {
			line-height: 1.3;
			span {
				display: flex;
				flex-direction: column;
				br {
					display: none;
				}
			}
		}

		&-textarea {
			grid-column: 1;
		}
	}

	.contact-us__subform {
		margin-bottom: 25px;
		.file__reader {
			order: 0;
			width: 100%;
			.contact-us__form-attach {
				width: 100%;
				max-width: 288px;
				height: 42px;
			}
		}
		.contact-us__form-checkbox {
			order: 1;
		}
	}
}
