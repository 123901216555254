@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.arrow-link {
	position: relative;
	background-color: transparent;
	border: none;
	color: $color-blue;
	letter-spacing: 0.04rem;
	transition: $transition;

	&.sm {
		font-family: 'IBM';
		font-size: 14px;
		line-height: 140%;

		&:hover {
			font-weight: bold;
		}
	}

	&:not(.sm) {
		text-transform: uppercase;

		&::before,
		&::after {
			content: '';
			position: absolute;
			right: -22px;
			background-color: $color-blue;
			height: 3px;
			width: 9px;
			transition: $transition;
		}

		&::before {
			top: 8px;
			transform: rotate(45deg);
		}

		&::after {
			bottom: 8px;
			transform: rotate(-45deg);
		}

		&:hover {
			color: $color-blue;

			&::before,
			&::after {
				right: -28px;
			}
		}
	}
}
