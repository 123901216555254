@import '../../../assets/style/variables';
@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.team__filter {
	margin-left: 3rem;
	width: 394px;
	position: relative;
	z-index: 1;
	&-click {
		display: none;
	}

	&-wrapper {
		overflow-y: auto;
		height: 746px;
		&::-webkit-scrollbar {
			width: 5px;
		}
	}
	&-box:first-child {
		margin-bottom: 1.5rem;
	}

	&-box.team__filter-tech {
		margin-bottom: 50px;

		.team__filter-title {
			margin-bottom: 1rem;
		}
	}

	&-title {
		font-family: 'IBM';
		font-size: 1rem;
		font-weight: bold;
		margin-bottom: 0.5rem;
	}

	&-subtitle {
		color: #a0a3ac;
		margin-bottom: 0.5rem;
	}

	&-box-item {
		display: inline-flex;
		flex-wrap: wrap;

		label {
			font-size: 0.75rem;
			padding: 0.125rem 0.5rem;
			height: auto;
			margin: 0 0.5rem 0.5rem 0;
		}
	}

	&-item {
		.specializations-item {
			margin-bottom: 0.5rem;
		}
	}

	@media (max-width: 1440px) {
		position: absolute;
		top: 0;
		right: 0;
		width: 365px;
		height: 100%;
		background-color: $color-white;
		box-shadow: 0px 4px 32px rgba(0, 0, 0, 0);
		transition: $transition;
		overflow: visible;
		padding: 20px;

		&-click {
			display: block;
			position: absolute;
			top: 50%;
			margin-top: -50px;
			background-color: #e4e5e7;
			width: 32px;
			height: 100px;
			margin-left: -52px;
			border: none;
			transform: rotate(180deg);
			cursor: pointer;
			transition: $transition;
			transition-property: background-color;

			@include bg-image('constructor-arrow.svg');
			background-size: 10px 25px;

			:hover {
				background-color: #cdced2;
			}
		}

		&.hide {
			right: -365px;
		}

		&.show {
			right: 0;
			width: 365px;
			max-width: 100%;
			z-index: 40;
			box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);

			.team__filter-click {
				top: 0;
				left: 0;
				height: 100%;
				margin-top: 0;
				margin-left: -32px;
				transform: rotate(360deg);
			}
		}

		&.team__filter-tech {
			height: 1400px;
		}
	}

	@media (max-width: 768px) {
		top: 0;
		margin-left: 0;
		background-color: transparent;
		width: auto;
		padding: 0;
		&.hide {
			position: relative;
			right: auto;
			left: auto;
		}
		&-wrapper {
			overflow-y: visible;
		}
	}
}
