@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.team__drop-buttons {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 26px;
}
.team__drop-clear {
	background-color: transparent;
	border: none;
	padding-right: 2rem;
	font-family: 'IBM';
	font-weight: bold;
	font-size: 1rem;
	color: $color-blue;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		width: 24px;
		height: 24px;
		background-size: contain;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		@include bg-image('icons/clean.svg');
	}

	&.hidden {
		visibility: hidden;
	}

	&.show {
		visibility: visible;
	}
}
.teams {
	.team__drop-buttons {
		padding-left: 55px;
	}
}
.teams__drop {
	.team__drop-buttons {
		margin-left: 1rem;
		padding-right: 30px;
	}
}
