@import "../../assets/style/variables";
@import "../../assets/style/colors";
@import "../../assets/style/mixins";

.contact-us {
  &__wrapper {
    height: auto;
    width: 100%;
    background-color: #4d5062;
    position: relative;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 16px;
    height: 100%;
    padding: 100px 0;
    &.show {
      position: relative;
      z-index: 1;

      @include bg-image("ContactUsBG.png", -320px);

      &::after {
        content: "";
        position: absolute;
        top: 300px;
        left: 400px;
        width: 48px;
        height: 52px;

        // @include bg-image("icons/x-map-mark.svg", 50% 40%, auto);
      }
    }
  }

  &__contacts {
    &-wrapper {
      display: flex;
      justify-content: center;
      max-width: 510px;
    }

    &-title {
      font-size: 3rem;
      font-weight: bold;
      text-transform: uppercase;
      line-height: 56px;
      letter-spacing: 0.04em;
      color: $color-white;
    }
    .career-page__footer-text {
      margin-top: 16px;
      width: 510px;
      color: $color-white;
    }

    &-adress {
      max-width: 300px;
      margin-top: 30px;

      &-title {
        margin-bottom: 10px;
        font-size: 1rem;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: $color-light-gray;
        text-transform: uppercase;
      }

      .adress-text {
        display: block;
        font-family: "IBM";
        font-size: 1rem;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: $color-white;
      }

      .adress {
        margin-bottom: 36px;
      }

      .tel:hover {
        opacity: 0.6;
      }

      .email {
        margin-top: 5px;

        &:hover {
          opacity: 0.6;
        }
      }
    }

    &-social {
      &-wrapper {
        display: flex;
        margin-top: 10px;
      }

      &-title {
        margin-top: 45px;
        font-size: 1rem;
        font-weight: bold;
        text-transform: uppercase;
        line-height: 24px;
        letter-spacing: 0.04em;
        color: $color-white;
      }

      &-link {
        .img__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 28px;
          height: 28px;
          margin-right: 0.5rem;
          background-color: rgba(255, 255, 255, 1);
          border-radius: 5px;
          transition: $transition;
          z-index: 1;

          &.blue,
          &.yellow,
          &.red {
            &::before {
              content: "";
              display: block;
              position: absolute;
              bottom: 0;
              left: 50%;
              height: 85%;
              width: 85%;
              transform: translateX(-50%);
              z-index: 0;
            }
          }

          &.blue::before {
            box-shadow: 0px 8.4px 16.8px rgba(24, 119, 242, 0.24),
              0px 4.28267px 8.4px rgba(24, 119, 242, 0.16),
              0px 1.16667px 2.8px rgba(24, 119, 242, 0.2);
          }

          &.yellow::before {
            box-shadow: 0px 8.4px 16.8px rgba(242, 220, 24, 0.24),
              0px 4.28267px 8.4px rgba(235, 24, 242, 0.16),
              0px 1.16667px 2.8px rgba(38, 24, 242, 0.2);
          }

          &.red::before {
            box-shadow: 0px 8.4px 16.8px rgba(237, 29, 36, 0.16),
              0px 4.28267px 8.4px rgba(237, 29, 36, 0.08),
              0px 1.16667px 2.8px rgba(237, 29, 36, 0.16);
          }

          .blur {
            position: absolute;
            width: 20px;
            height: 19px;
            z-index: 0;
            top: 17%;
            -webkit-filter: blur(3px);
            filter: blur(3px);
            opacity: 0.7;
            transition: $transition;
          }

          &:hover {
            transform: translateY(-4px);
          }

          img {
            position: relative;
            z-index: 1;
          }
        }
      }
    }
  }

  @media (max-width: 1440px) {
    & {
      &__wrapper {
        height: auto;
      }

      &__body {
        padding: 56px 0 64px;

        &.show {
          @include bg-image("backgrounds/contactUs1024.svg", -20px 0);

          &::after {
            top: 240px;
            left: 295px;
          }
        }

        .contact-us__contacts-wrapper {
          max-width: 440px;
        }
        .career-page__footer-text {
          width: 440px;
        }

        .contact-us__contacts-adress {
          .adress-text {
            font-size: 0.875rem;
          }
        }

        .contact-us__contacts-social-title {
          margin-top: 112px;
        }

        .contact-us__form-wrapper {
          max-width: 460px;
        }
      }
      &__form-checkbox {
        min-height: 36px;
      }
    }
  }

  @media (max-width: 1024px) {
    & {
      &__wrapper {
        height: auto;
      }

      &__body {
        flex-direction: column;
        padding: 36px 0 40px 0px;

        &.show {
          background-image: none;

          &::after {
            content: none;
            top: 260px;
            left: 390px;
          }
        }

        .h1 {
          font-size: 1.25rem;
          line-height: 32px;
        }

        .h3 {
          font-size: 0.875rem;
          line-height: 20px;
        }

        .adress-text {
          font-size: 0.875rem;
          line-height: 140%;
        }

        .contact-us__contacts-wrapper {
          justify-content: flex-start;

          .contact-us__contacts-adress {
            margin-top: 2rem;
          }

          .tel {
            margin-top: 18px;
          }

          .contact-us__contacts-social-title {
            margin-top: 40px;
          }
        }

        .contact-us__form-wrapper {
          margin-top: 75px;
          width: 100%;
          max-width: 100%;
        }

        .contact-us__form-attach {
          margin-left: auto;
        }

        .arrow-button {
          min-width: 220px;

          .arrow-button__text {
            margin-left: 35px;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    & {
      &__wrapper {
        height: auto;

        .contact-us__form-wrapper {
          width: 100%;
        }
      }

      &__body {
        flex-direction: column;
        padding: 33px 0px 64px 0px;

        &.show {
          background-image: none;
          &::after {
            content: none;
            background: none;
          }
        }

        .h1 {
          font-size: 1.5rem;
          line-height: 32px;
          max-width: 300px;
        }

        .career-page__footer-text {
          width: 100%;
          white-space: break-spaces;
        }

        .buttons-wrapper {
          grid-template-columns: 1fr;
        }

        .contact-us__contacts-wrapper {
          width: 100%;

          .adress {
            margin-bottom: 16px;
            font-size: 0.875rem;
          }

          .tel {
            margin-top: 20px;
            font-size: 0.875rem;
          }

          .contact-us__contacts-social-title {
            margin-top: 40px;
          }

          .contact-us__contacts-social-wrapper {
            width: 190px;
            justify-content: space-between;
            margin-top: 5px;
          }
        }

        .contact-us__form-wrapper {
          margin-top: 0px;
        }
        .contact-us__form-input {
          max-width: 100%;
        }
      }
    }
  }
}
