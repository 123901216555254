@import '../../../assets/style/colors';

.community-page {
	margin-bottom: 90px;

	.events-feed {
		margin-top: 80px;

		.events-feed__filters {
			display: flex;
			align-items: center;
			margin-bottom: 32px;
			overflow-x: auto;

			.oval-button {
				margin-right: 9px;
			}
		}

		.events-wrapper {
			display: grid;
			min-height: 50vh;
			&.empty {
				min-height: 10vh;
			}

			.event-item {
				display: flex;
				flex-direction: column;
				align-items: stretch;
				position: relative;
				height: 100%;
				transition: all 0.3s ease-out;

				&::before {
					content: '';
					position: absolute;
					top: -16px;
					left: -16px;
					height: calc(100% + 32px);
					width: calc(100% + 32px);
					background-color: transparent;
					transition: all 0.3s ease-out;
					z-index: 0;
				}

				&:hover {
					&::before {
						background-color: $color-light-gray;
					}

					.event-item__img {
						.img-wrapper div {
							transform: scale(1.05);
						}

						.blur {
							opacity: 0.75;
						}
					}
				}

				&__info-wrapper {
					flex: 1;
					display: flex;
					flex-direction: column;
					position: relative;
					z-index: 1;
				}

				&__img {
					position: relative;
					margin-bottom: 24px;
					z-index: 1;

					.img-wrapper {
						height: 100%;
						width: 100%;
						overflow: hidden;

						div {
							height: 100%;
							width: 100%;
							background-position: center;
							background-size: cover;
							background-repeat: no-repeat;
							transition: transform 0.3s ease-out;
						}
					}

					.blur {
						top: 8px;
						left: 50%;
						height: 100%;
						width: 96.5%;
						background-position: center;
						background-size: cover;
						background-repeat: no-repeat;
						transform: translateX(-50%);
						transition: opacity 0.3s ease-out;
					}
				}

				&__tag {
					margin-bottom: 8px;
					font-family: 'Commissioner';
					font-weight: bold;
					font-size: 12px;
					text-transform: uppercase;
					color: $color-blue;
					opacity: 0.8;
				}

				&__title {
					margin-bottom: 12px;
					font-family: 'Commissioner';
					font-weight: bold;
					font-size: 24px;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					color: $color-main;
				}

				&__text {
					overflow: hidden;

					span {
						font-family: 'IBM';
						font-size: 16px;
						line-height: 140%;
						color: $color-purple;
					}
				}
			}
		}

		.main-team__button {
			display: block;
			margin: 40px auto 0;
			padding: 0.9rem 3rem 0.9rem 3rem;
			color: $color-blue;

			&::before,
			&::after {
				display: none;
			}
		}
	}
}

@media (min-width: 1025px) {
	.community-page {
		.events-wrapper {
			grid-template-columns: repeat(6, 1fr);
			gap: 32px;

			& > {
				a {
					grid-column: span 2;

					.event-item__img {
						height: 175px;
					}
				}

				a:nth-child(1) {
					grid-column: span 4;
				}

				a:nth-child(-n + 2) {
					.event-item__img {
						height: 390px;
					}
				}

				a:nth-child(6),
				a:nth-child(7) {
					grid-column: span 3;

					.event-item {
						&__img {
							position: relative;
							height: 370px;
							margin-bottom: 0;

							&::before {
								content: '';
								position: absolute;
								top: 0;
								left: 0;
								height: 100%;
								width: 100%;
								background: linear-gradient(180deg, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.8) 100%);
								transition: all 0.3s ease-out;
								z-index: 1;
							}
						}

						&__info-wrapper {
							position: absolute;
							left: 0;
							bottom: 0;
							width: 100%;
							padding: 16px;
						}

						&__tag,
						&__title,
						&__text {
							& span {
								color: $color-white;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1440px) and (min-width: 768px) {
	.community-page {
		.events-feed {
			margin-top: 63px;

			.events-feed__filters {
				margin-bottom: 22px;
				label {
					font-size: 0.875rem;
					line-height: 140%;
					height: 36px;
				}
			}

			.events-wrapper {
				.event-item {
					&__title {
						font-size: 16px;
					}

					&__text {
						font-size: 14px;
					}
				}
			}

			.main-team__button {
				margin: 40px auto 60px;
			}
		}
	}
}

@media (max-width: 1024px) and (min-width: 768px) {
	.community-page {
		.container {
			max-width: 100%;
		}

		.events-feed {
			.events-wrapper {
				grid-template-columns: repeat(2, 1fr);
				gap: 24px;

				& > {
					a {
						.event-item {
							&::before {
								top: -12px;
								left: -12px;
								height: calc(100% + 24px);
								width: calc(100% + 24px);
							}

							&__img {
								height: 220px;
							}
						}
					}

					a:nth-child(-n + 2) {
						.event-item__img {
							height: 220px;
						}
					}

					a:nth-child(5) {
						grid-column: span 2;

						.event-item {
							&__img {
								position: relative;
								height: 260px;
								margin-bottom: 0;

								&::before {
									content: '';
									position: absolute;
									top: 0;
									left: 0;
									height: 100%;
									width: 100%;
									background: linear-gradient(
										180deg,
										rgba(0, 0, 0, 0) 40%,
										rgba(0, 0, 0, 0.8) 100%
									);
									transition: all 0.3s ease-out;
									z-index: 1;
								}
							}

							&__info-wrapper {
								position: absolute;
								left: 0;
								bottom: 0;
								width: 100%;
								padding: 16px;
							}

							&__tag,
							&__title,
							&__text {
								& span {
									color: $color-white;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.community-page {
		.community-page-wrapper {
			margin-top: 70px;
		}

		.events-feed {
			margin-top: 40px;

			.events-feed__filters {
				position: relative;
				left: -16px;
				width: calc(100% + 32px);
				margin-bottom: 10px;
				padding: 0 16px 14px 16px;
				&::-webkit-scrollbar {
					height: 0;
				}
			}

			.events-wrapper {
				grid-template-columns: 1fr;
				gap: 32px;

				& > {
					a {
						.event-item {
							&::before {
								top: -8px;
								left: -8px;
								height: calc(100% + 16px);
								width: calc(100% + 16px);
							}
							&__title {
								font-size: 12px;
							}

							&__text {
								span {
									font-size: 12px;
								}
							}

							&__img {
								height: 150px;
							}
						}
					}
				}
			}
		}
	}
}
