@import '../../../../assets/style/colors';
@import '../../../../assets/style/variables';

.team {
	&__skills {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		transition: $transition;
		&.open {
			flex-direction: column;
			flex-wrap: nowrap;
			align-items: flex-start;
		}

		&-button {
			background-color: transparent;
			border: none;
			margin-top: 0.5rem;
			margin-left: 0.5rem;
			color: $color-blue;
			transition: $transition;
			&.open {
				align-self: flex-end;
			}
		}

		&-item {
			margin-right: 0.5rem;
			margin-top: 0.75rem;
		}

		.team__skill-wrapper {
			display: flex;
			flex-direction: column;
		}

		&-item-box {
			margin: 0.75rem 0;
		}

		&-items {
			display: flex;
			flex-wrap: wrap;
		}

		label {
			border-radius: 50px;
			background-color: rgba(55, 59, 79, 0.14);
			padding: 0.3rem 0.5rem;
			font-size: 0.75rem;
			font-family: 'IBM';
			font-weight: normal;
			line-height: 20px;
			height: auto;
		}
	}
	@media (max-width: 768px) {
		&__skills {
			width: 240px;
		}
	}
}
