.confirm-request {
	&__form-title {
		margin-bottom: 30px;
	}

	&__wrapper {
		.popup-title {
			margin-bottom: 2.5rem;
		}
	}

	@media (max-width: 992px) {
		&__wrapper {
			.popup-wrapper {
				padding: 20px 40px;
				margin: 0 1rem;
			}
			.popup-title {
				margin-bottom: 1.5rem;
			}
		}
		&__title.h3,
		&__form-title.h3 {
			font-size: 1rem;
		}
	}

	@media (max-width: 768px) {
		&__wrapper {
			.popup-wrapper {
				padding: 0.75rem 0 1rem;
			}
			.popup-title {
				padding: 0 1rem;
			}
		}

		.confirm-request__title.h3 {
			padding: 0 1rem;
		}
		.timeline {
			height: 80px;
			overflow: hidden;
			padding: 0 1rem;
			.timeline_scroll-wrapper {
				overflow-x: auto;
				.timeline_scroll {
					width: 500px;
				}
			}
		}
		.confirm-request__form-title.h3 {
			padding: 0 1rem;
		}

		.contact-us__form-wrapper {
			padding: 0 1rem;
		}
	}
}

.timeline {
	margin-bottom: 2rem;
}
