@import '../../../assets/style/colors';

.event {
	margin-top: 5rem;

	.container {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__wrapper {
		max-width: 860px;

		.event__img-mobile {
			display: none;
		}
	}

	&__id {
		font-family: 'Commissioner';
		font-weight: bold;
		font-size: 1rem;
		color: $color-blue;
		text-transform: uppercase;
		margin-bottom: 0.25rem;
	}

	&__img {
		position: relative;
		z-index: 0;
		margin: 2.5rem 0 3rem;

		img {
			max-width: 100%;
			width: 100%;
			height: auto;
			max-height: 500px;
			object-fit: cover;
		}

		.blur {
			width: 98%;
			height: 100%;
			top: 5px;
			right: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}
	}

	&__modal {
		background-color: $color-light-gray;
		max-width: 368px;
		padding: 1.5rem 2.5rem;
		position: sticky;
		top: 7rem;
		margin-top: 11rem;

		&-title.h2 {
			text-transform: uppercase;
			font-size: 1rem;
			max-width: 226px;
			line-height: 1.5;
			text-align: center;
			margin-bottom: 2rem;
		}

		button {
			box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15),
				0px 4px 16px rgba(62, 100, 200, 0.4);
			padding: 1rem 1.85rem;
		}
	}
}

.event-like {
	margin: 14.25rem 0 7.5rem;

	.main__title {
		margin-left: 0.5rem;
		letter-spacing: 0.04rem;
	}

	.container {
		max-width: 1328px;
	}

	&__wrapper {
		margin-top: 1.5rem;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 16px;
	}

	&__box {
		background-color: $color-light-gray;
		max-width: 432px;
		padding: 1rem;

		.main-find__img {
			max-width: 400px;
			height: 176px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	&__item {
		height: 175px;
	}

	&__id {
		font-family: 'Commissioner';
		font-weight: bold;
		color: $color-blue;
		text-transform: uppercase;
		font-size: 0.75rem;
		margin-top: 1rem;
	}

	&__title {
		text-transform: uppercase;
		margin-top: 0.35rem;
		letter-spacing: 0.03rem;
		color: $color-purple;
	}

	&__text {
		font-family: 'IBM';
		font-size: 1rem;
		color: $color-purple;
		font-weight: normal;
		margin-top: 0.5rem;
		line-height: 1.35;
	}
}

@media (max-width: 1440px) {
	.event {
		margin-top: 4rem;
		margin-bottom: 5rem;
		&__wrapper {
			max-width: 625px;
		}

		&__img {
			img {
				max-height: 345px;
			}
		}

		&__text {
			.event__img {
				margin: 2.5rem 0 2rem;
			}
		}

		&__modal {
			padding: 1rem 1.5rem 1.5rem;
			margin-top: 6rem;
			width: 100%;
			max-width: 305px;

			&-title.h2 {
				margin-bottom: 1rem;
				text-align: left;
				max-width: 100%;
			}
			.blue-button {
				width: 100%;
			}
		}

		&-like {
			display: none;
		}
	}
}

@media (max-width: 1024px) {
	.event {
		margin-top: 2rem;

		&__modal {
			display: none;
		}

		&__wrapper {
			max-width: 100%;
		}

		&__text {
			max-width: 100%;

			.event__img {
				margin-top: 1.5rem;
			}
		}

		&__galery-item {
			height: 138px;
			.wide {
				height: 196px;
			}
		}

		&__img {
			img {
				width: 100%;
				max-height: 350px;
			}
		}
	}
}

@media (max-width: 767px) {
	.event {
		margin-top: 0;

		.container {
			padding: 0;

			.event__wrapper {
				.event__img-mobile {
					display: block;
					margin-top: 0;
				}

				.event__id,
				.main__title {
					padding: 0 1rem;
					margin-bottom: 0.5rem;
				}

				.event__text {
					padding: 0 1rem;

					.event__img {
						display: none;
					}

					p {
						margin-bottom: 1rem;
					}

					blockquote {
						font-size: 0.75rem;
						margin-bottom: 1rem;
					}

					iframe {
						height: 415px !important;
						margin-bottom: 1.5rem;
					}
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.event {
		.container {
			.event__wrapper {
				.event__text {
					iframe {
						height: 300px !important;
					}
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.event {
		.container {
			.event__wrapper {
				.event__text {
					iframe {
						height: 240px !important;
					}
				}
			}
		}
	}
}

@media (max-width: 375px) {
	.event {
		.container {
			.event__wrapper {
				.event__text {
					iframe {
						height: 195px !important;
					}
				}
			}
		}
	}
}
