@import '../../assets/style/mixins';
@import '../../assets/style/colors';

.preloader-component {
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	width: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	overflow: hidden;
	backdrop-filter: blur(5px);

	& > .preloader {
		height: 28px;
		width: 28px;

		@include bg-image('preloader.png');
		@include spin;
	}
}
