@import '../../../assets/style/variables';
@import '../../../assets/style/colors';

.contact-us__form-textarea {
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	grid-column: 1 / span 2;
	display: flex;
	width: 100%;
	min-height: 145px;
	padding: 25px 15px 5px 15px;
	transition: $transition;
	border: 1px solid rgba(55, 59, 79, 0.3);
	background-color: $color-input-gray;
	cursor: pointer;

	&:hover {
		background-color: $color-white;
		& > textarea {
			background-color: $color-white;
		}
	}

	& > label {
		position: absolute;
		top: 13px;
		left: 16px;
		font-family: 'IBM';
		font-size: 1rem;
		line-height: 140%;
		color: $color-purple;
		pointer-events: none;
		transition: $transition;
	}

	& > textarea {
		width: 100%;
		height: 100%;
		font-family: 'IBM';
		font-size: 1rem;
		line-height: 140%;
		transition: $transition;
		resize: none;
		outline: none;
		border: none;
		color: $color-purple;
		cursor: pointer;
		background-color: $color-input-gray;

		&::-webkit-scrollbar {
			width: 0px;
		}

		&:hover {
			outline: none;
			background-color: $color-white;
		}
	}
	&.error {
		textarea {
			border-color: $color-red;
		}
	}

	&.full {
		background-color: $color-white;
		& > textarea {
			background-color: $color-white;
		}
	}

	&.full > label,
	& > textarea:focus + label {
		top: 1px;
		font-size: 0.75rem;
		line-height: 20px;
		padding-top: 5px;
	}
}

.contact-us__form.white {
	.contact-us__form-textarea {
		background-color: $color-white;
		& > textarea {
			background-color: $color-white;
		}
	}
}
