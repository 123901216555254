@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.dropzone {
	margin: 0 0.5rem 0.5rem;
	height: 136px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	background-image: url('data:image/svg+xml;utf8,<svg width="100%" height="136" xmlns="http://www.w3.org/2000/svg"><rect width="100%" height="100%" style="fill: none; stroke: rgba(55, 59, 79, 0.2); stroke-width: 2; stroke-dasharray: 12 10"/></svg>');

	span {
		position: relative;
		max-width: 335px;

		&::before {
			content: '';
			position: absolute;
			width: 45px;
			height: 45px;
			left: -5rem;
			top: 50%;
			transform: translateY(-50%);

			@include bg-image('dropzone.svg');
		}
	}
}

.quicksearch {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 9rem;

	span {
		text-align: center;
		margin-bottom: 1.15rem;
	}

	.header__button {
		margin-left: 0;
		color: $color-main;
	}
}

@media (max-width: 1024px) {
	.quicksearch {
		margin-top: 6rem;

		&-button {
			display: block;
		}
	}

	.dropzone {
		justify-content: center;
		align-items: center;

		span {
			padding-top: 2rem;
			text-align: center;

			&::before {
				width: 40px;
				height: 40px;
				left: 50%;
				top: 0;
				margin-left: -20px;
			}
		}
	}
}

@media (max-width: 768px) {
	.dropzone {
		span {
			margin: 0 auto;
		}
	}

	.quicksearch {
		background-color: $color-white;
		margin: 0;
		padding-top: 1.5rem;

		span {
			font-size: 0.9rem;
			margin-bottom: 1.25rem;
		}

		&-button {
			box-shadow: 0px 2px 4px rgba(73, 67, 98, 0.03), 0px 3px 8px rgba(73, 67, 98, 0.05),
				0px 4px 16px rgba(73, 67, 98, 0.15);
			padding: 1rem 4.5rem;
			&.h3 {
				font-size: 1rem;
			}
		}
	}
}
