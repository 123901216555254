$color-blue: #5d83e4;
$color-blue-focus: #456ed6;
$color-main: #373b4fe3;
$color-white: #ffffff;
$color-input-gray: #d8d9dd;
$color-light-gray: #f8f9fa;
$color-purple: #494362;
$color-red: #eb5757;
$color-green: #27ae60;
$color-transparent-gray: rgba(55, 59, 79, 0.2);
