@import "./../../../assets/style/colors";
@import "./../../../assets/style/mixins";

.main-page {
  @include bg-image("main_peopleXX.png", center 0, 1820px auto);
}
.main-header {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 600px;

  &::before {
    content: "";
    position: absolute;
    border-bottom: 1px solid #373b4f1c;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 80px;
  }

  & > .container {
    flex: 1;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    height: 100%;
    max-width: 580px;
    padding: 120px 0 90px;
  }

  &__text {
    letter-spacing: 0.05rem;
  }

  .main__title {
    margin-bottom: 16px;

    .nonbreak {
      white-space: nowrap;
    }
  }
}

.main-who-we-are {
  position: relative;
  padding: 115px 0;

  &__wrapper {
    display: flex;
  }

  &__left-block {
    margin-right: 125px;
    min-width: 50%;
  }

  .main__title {
    margin-bottom: 35px;
  }

  .title-text {
    max-width: 575px;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.04em;
    font-family: "IBM", sans-serif;
  }

  &__box.adaptive {
    display: none;
  }

  &__text-subtitle {
    position: relative;
    color: $color-blue;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-top: 25px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      background-color: $color-blue;
      height: 3px;
      width: 9px;
      left: -25px;
    }

    &::before {
      top: 8px;
      transform: rotate(45deg);
    }

    &::after {
      top: 13px;
      transform: rotate(-45deg);
    }
  }

  &__img {
    margin-top: 50px;
    position: relative;
    z-index: 0;
    cursor: pointer;
    max-width: 750px;
    width: 100%;

    .blur {
      width: 98%;
      height: 100%;
      top: 5px;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  .who-we-are-video {
    width: 100%;
    height: 295px;
  }

  &__quote {
    margin-top: 40px;
    font-size: 12px;
    line-height: 20px;
    color: $color-blue;
  }

  &__text {
    & span {
      display: block;
      margin-top: 20px;
    }
  }
}

.main-services {
  background-color: $color-light-gray;
  padding: 120px 0 140px;

  .main__title {
    margin-bottom: 2.5rem;
    text-shadow: -3px -3px 6px $color-white, 3px 3px 6px rgba(49, 69, 106, 0.3);
  }

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
  }
}

.why {
  padding: 120px 0 145px;

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__list {
    margin-top: 2.4rem;
    max-width: 505px;
  }

  &__item {
    position: relative;
    padding-left: 1.5rem;
    margin-bottom: 1.7rem;

    &::before,
    &::after {
      content: "";
      position: absolute;
      box-shadow: 0px 2px 4px rgba(93, 131, 228, 0.5);
      background-color: $color-blue;
      height: 3px;
      width: 9px;
      left: 0;
    }

    &::before {
      top: 8px;
      transform: rotate(45deg);
    }

    &::after {
      top: 13px;
      transform: rotate(-45deg);
    }
  }

  &__box:last-child {
    position: relative;
    z-index: 0;
    margin-top: 6rem;

    .blur {
      width: 736px;
      height: 100%;
      top: 8px;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }
}

.main-team {
  padding: 40px 0 80px;

  .main__title {
    letter-spacing: 0.11rem;
  }

  &__title-text {
    margin-top: 1.7em;
    max-width: 615px;
  }

  &__wrapper {
    display: grid;
    position: relative;
    margin-top: 2rem;
    padding: 40px 0 48px;
    z-index: 0;

    &::before {
      content: "";
      position: absolute;
      background-color: $color-light-gray;
      width: calc(100% + 80px);
      height: 100%;
      top: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  &__box {
    display: grid;
    grid-auto-flow: column;
    gap: 32px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: $color-white;
    border: 1px solid $color-transparent-gray;

    &::before {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: 8px;
      height: calc(100% - 8px);
      width: calc(100% - 16px);
      box-shadow: 0px 2px 4px rgba(55, 59, 79, 0.05), 0px 3px 8px rgba(55, 59, 79, 0.08),
        0px 4px 16px $color-transparent-gray;
      border-radius: 8px;
      z-index: -1;
    }

    &-img {
      width: 100%;
      position: relative;
      background-repeat: no-repeat;
      background-size: 500px;
      background-position: 20% 42%;
      z-index: 0;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: $color-white;
        z-index: -1;
        opacity: 0.9;
        -moz-backdrop-filter: blur(7px);
        -o-backdrop-filter: blur(7px);
        -ms-backdrop-filter: blur(7px);
        -webkit-backdrop-filter: blur(7px);
        backdrop-filter: blur(7px);
      }

      img {
        border-radius: 50%;
        display: block;
        margin: 0 auto;
        margin-top: 1rem;
      }

      .name {
        text-transform: uppercase;
        text-align: center;
        margin: 0.5rem 0;
      }
    }

    &-title {
      font-family: "IBM";
      font-size: 1rem;
      font-weight: normal;
      letter-spacing: 0.04rem;
      width: 100%;
      padding: 1.6rem 1.25rem 0;

      span {
        border-bottom: 1px solid $color-transparent-gray;
        display: inline-block;
        width: 100%;
        padding-bottom: 1.25rem;
      }
    }

    &-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0.65rem 1rem 2rem;
    }

    &-skill {
      color: $color-main;
      background-color: rgba(55, 59, 79, 0.14);
      border-radius: 50px;
      padding: 2px 8px;
      margin: 4px;
    }
  }

  &__text {
    text-align: center;
    margin: 60px 0;
    letter-spacing: 0.05rem;
  }

  a {
    justify-self: center;
  }
}

.main-tech {
  padding: 75px 0;

  .main__title {
    margin-bottom: 58px;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.main-find {
  padding: 64px 0;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: calc(100% - 290px);
    background-color: $color-light-gray;
    clip-path: polygon(0 0, calc(100% - 210px) 0, 100% 50%, calc(100% - 210px) 100%, 0 100%);
    z-index: -1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 430px;

    @include bg-image("X-back.svg", left, auto);
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__img {
    position: relative;
    z-index: 0;

    .blur {
      width: 98%;
      height: 100%;
      top: 8px;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  &__box {
    width: 100%;
    max-width: 755px;

    padding: 0;

    .flex-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
    }

    .main__title {
      margin-bottom: 10px;
    }
  }

  &__text {
    margin-bottom: 20px;
    font-size: 1rem;
    font-family: "IBM";
    font-weight: normal;
    color: $color-purple;

    max-width: 530px;
    line-height: 1.4;
  }
}

.main-selection {
  padding: 150px 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 240px 231px 269px 260px;
    justify-content: space-between;
    margin-top: 3.25rem;
  }

  &__box {
    position: relative;
    width: 100%;

    &:not(:first-child)::before {
      content: "";
      display: block;
      position: absolute;
      left: -25%;
      transform: translateX(50%);
      bottom: 7px;
      height: 24px;
      width: 16px;

      @include bg-image("icons/chevrone-pale.svg");
    }
  }

  &__title {
    min-width: 35px;
    font-size: 80px;
    font-family: "Commissioner";
    font-weight: normal;
    color: $color-blue;
  }

  &__text {
    font-family: "IBM";
    font-size: 1rem;
    margin-top: 0.9rem;
    line-height: 1.3;
  }
}

.main-community {
  padding: 95px 0 40px;

  strong {
    white-space: nowrap;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__img {
    margin-top: 0.5rem;
    position: relative;
    z-index: 0;
    cursor: pointer;
    max-width: 750px;
    width: 100%;

    .blur {
      width: 98%;
      height: 100%;
      top: 5px;
      right: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  &__text {
    margin-top: 1.5rem;
    max-width: 510px;
    width: 100%;

    p {
      margin-bottom: 0.35rem;
    }
  }

  .community-video {
    height: 430px;
    width: 100%;
  }
}

.main__event {
  margin-bottom: 150px;

  &__wrapper {
    position: relative;
    z-index: 0;
    padding: 40px 0 68px;

    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 80px);
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-light-gray;
      z-index: -1;
    }
  }

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .arrow-link {
      margin-right: 1.25rem;

      &:not(:hover) {
        color: $color-main;
      }
    }
  }

  &__box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    .link-box {
      max-width: 415px;
    }
  }

  .link-box__img {
    position: relative;

    img {
      position: relative;
      z-index: 1;
    }

    .blur {
      top: 5px;
      left: 50%;
      width: 98%;
      height: 100%;
      transform: translateX(-50%);
      z-index: 0;
    }
  }

  .link-box__title {
    max-width: 380px;
    text-transform: uppercase;
    letter-spacing: 0.04rem;
    font-family: "Commissioner";
    font-size: 1rem;
    font-weight: bold;
    line-height: 24px;
  }

  .link-box__text {
    margin-bottom: 16px;
    line-height: 1.35;
    font-size: 1rem;
    font-family: "IBM";
    font-weight: normal;
    line-height: 22px;
  }

  .pseudo-link {
    font-size: 1rem;
    text-transform: none;
    padding-left: 1.25rem;
    &:before,
    &:after {
      right: auto;
      left: 0;
    }
  }
}

.main__title {
  text-transform: uppercase;
  letter-spacing: 0.09rem;
}

@media (max-width: 1440px) {
  .main-page {
    @include bg-image("main_peopleX.png", center 0, auto 1190px);
  }
  .main-header {
    height: 424px;

    &__wrapper {
      max-width: 580px;
      padding: 64px 0 44px;
    }
  }

  .main-services {
    padding: 80px 0;

    .main__title {
      margin-bottom: 1.5rem;
    }
  }

  .why {
    padding: 70px 0;
  }

  .why__box {
    width: 50%;
    &:first-child {
      margin-top: 0.8rem;
    }

    img {
      width: 100%;
    }

    &:last-child {
      margin-top: 4.75rem;

      .blur {
        height: 282px;
        width: 100%;
      }
    }
  }

  .why__list {
    margin-top: 1.25rem;
  }

  .why__item {
    margin-bottom: 1.1rem;
  }

  .main-team {
    padding-top: 35px;
    &__title-text {
      margin-top: 0.5rem;
    }

    &__wrapper {
      padding: 16px 0 32px;
      margin-top: 1.5rem;

      &::before {
        width: calc(100% + 32px);
      }
    }

    &__box {
      gap: 16px;
    }

    &__item-img {
      img {
        width: 45%;
      }
    }

    &__item-title {
      padding: 0.5rem 0.5rem 0;
      font-size: 0.875rem;
      line-height: 140%;

      span {
        padding-bottom: 0.4rem;
      }
    }

    &__item-list {
      margin: 0.6rem 0.6rem;
    }

    &__item-skill {
      border-radius: 50px;
      padding: 2px 12px;
    }

    &__text {
      font-size: 1.5rem;
      margin: 2.5rem 0;
    }
  }

  .main-tech {
    padding: 45px 0 80px;
    .main__title {
      margin-bottom: 15px;
    }

    .main-tech__wrapper {
      display: grid;
      grid-template: "a b";
      grid-gap: 10px;

      .techno-card__box {
        max-width: 465px;
        margin: 0 0 0 -20px;

        .techno-card__title {
          letter-spacing: 1px;
        }

        .techno-card__item {
          overflow: auto;
        }

        .techno-card__text {
          max-width: 350px;
          line-height: 1.3;
        }

        .techno-card__img {
          height: 100%;
          min-width: 90px;
          margin-left: 0;

          img {
            height: 100px;
          }
        }
      }
    }
  }

  .main-find {
    padding: 50px 0 60px;
    overflow: hidden;

    &::before {
      width: calc(100% - 170px);
      clip-path: polygon(0 0, calc(100% - 185px) 0, 100% 50%, calc(100% - 185px) 100%, 0 100%);
    }

    &::after {
      width: 300px;
    }

    .main-find__box {
      padding: 0;
      z-index: 1;
      .main__title {
        margin-bottom: 6px;
      }
    }

    .main-find__img {
      margin-right: 2rem;
    }

    .main-find__text {
      margin-top: 0.3rem;
      margin-bottom: 32px;
      font-family: "IBM";
      font-size: 0.875rem;
      line-height: 140%;
    }
  }

  .main-selection {
    padding: 80px 0;

    .main-selection__wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 2.5rem;

      .main-selection__box {
        max-width: 192px;

        .main-selection__title {
          font-size: 64px;
          color: $color-blue;
        }
      }
    }
  }

  .main-community {
    padding: 35px 0;

    .main-community__wrapper {
      display: flex;
      justify-content: space-between;

      .main-community__box {
        width: 464px;

        .main-community__text {
          margin-top: 0;

          p {
            margin-top: 0.5rem;
          }
        }
      }

      .main-community__img {
        margin-top: 48px;
        width: 464px;

        .community-video {
          height: 230px;
        }
      }
    }
  }

  .main__event {
    margin-bottom: 40px;

    &__wrapper {
      padding: 24px 0 40px;

      &::before {
        width: calc(100% + 48px);
      }
    }

    .main__event__title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .main__title {
        font-size: 2rem;
        line-height: 40px;
        text-transform: uppercase;
      }
    }

    .main__event__box {
      .main__event__item {
        .main__event__text {
          font-size: 0.875rem;
        }

        .arrow-link {
          font-size: 0.875rem;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .main-page {
    background-position-x: center;
    background-size: auto 1430px;
  }
  .main-header {
    height: 508px;
    max-height: calc(100vh - 48px);
    background-position-x: 260px;
  }

  .main-who-we-are {
    padding: 80px 0;

    .title-text {
      font-size: 22px;
      line-height: 25px;
    }

    &__img {
      height: 210px;
      .who-we-are-video {
        height: 100%;
      }
    }

    &__left-block {
      margin-right: 80px;
    }

    &__text-subtitle {
      font-size: 14px;
      margin-top: 5px;
    }

    &__text {
      & span {
        margin-top: 5px;
      }
    }
  }

  .main-header__wrapper {
    max-width: 380px;
    padding: 55px 0 50px;
  }

  .main-services {
    padding: 60px 0;

    .main__title {
      max-width: 400px;
      margin-bottom: 20px;
    }

    &__wrapper {
      grid-template-columns: 1fr;
      gap: 44px;
    }

    .main-services__box {
      display: grid;
      grid-auto-columns: auto 1fr;
      column-gap: 24px;
      max-width: none;
    }

    .main-services__img {
      grid-row: 1 / span 4;
      width: 252px;

      img {
        height: 168px;
      }
    }

    .blur {
      width: 252px;
      height: 168px;
      grid-column: 1;
      margin-top: 5px;
    }

    .main-services__title {
      grid-column: 2;
      grid-row: 1;

      margin-top: 0;

      font-size: 1rem;
      line-height: normal;
      white-space: nowrap;
    }

    .main-services__text {
      grid-column: 2;
      grid-row: 2 / span 2;
      height: auto;

      margin: 0;
    }
  }

  .why {
    padding: 55px 0;

    &__wrapper {
      align-items: flex-start;
    }

    .why__box {
      width: 48%;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-top: 3.75rem;

        .blur {
          height: 100%;
        }
      }

      .why__item {
        margin-bottom: 1.2rem;
      }
    }
  }

  .main-team {
    padding: 48px 0 25px;

    .main-team__item {
      &:last-child {
        display: none;
      }
    }

    .main-team__text {
      font-size: 1.1rem;
      line-height: 24px;
      margin: 1.5rem 0 2rem;
    }
  }

  .main-tech {
    padding: 55px 0 70px;

    .main-tech__wrapper {
      .techno-card__box {
        height: 110px;
        padding: 0;
        margin: 0;
        position: relative;

        .techno-card__img {
          max-width: 95px;
          img {
            margin-top: 30px;
            height: 140px;
          }
        }

        .techno-card__item {
          display: flex;
          align-items: center;
          margin-left: 1.5rem;

          .techno-card__text {
            display: none;
          }
        }
        .techno-card__text-hover {
          position: absolute;
          opacity: 0;
          transition: 0.3s;
          background-color: $color-white;
          border: 1px solid $color-transparent-gray;
          height: 100%;
          width: 100%;
          max-width: 100%;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          padding: 1rem 0.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &.active {
            opacity: 1;
          }
        }
      }
    }
  }

  .main-find {
    padding: 30px 0 50px;

    &::before {
      width: calc(100% - 170px);
      clip-path: polygon(0 0, calc(100% - 130px) 0, 100% 50%, calc(100% - 130px) 100%, 0 100%);
    }

    &::after {
      width: 260px;
    }

    &__img {
      display: none;
    }

    .main-find__box {
      max-width: 550px;
      margin: 0 auto;
      .flex-container {
        align-items: center;
      }
      .main__title {
        margin-bottom: 13px;
        text-align: center;
      }
    }

    .main-find__text {
      margin-top: 0.8rem;
      text-align: center;
    }
  }

  .main-selection {
    padding: 45px 0 60px;

    .main__title {
      margin-bottom: 18px;
    }

    .main-selection__wrapper {
      flex-direction: column;
      margin-top: 0;

      .main-selection__box {
        display: flex;
        align-items: center;
        max-width: 490px;

        &::before {
          display: none;
        }

        &:not(:last-child) {
          margin-bottom: 25px;
        }

        .main-selection__text {
          width: 436px;
          height: 44px;
          margin-left: 60px;
          margin-top: 0;
          font-size: 1.1rem;
          line-height: 24px;
          letter-spacing: 0.04em;
        }
      }
    }
  }

  .main-community {
    padding: 60px 0 38px;

    .main-community__wrapper {
      align-items: flex-start;

      .main-community__box {
        width: 50%;
        margin-right: 1rem;

        .main-community__text {
          margin-top: 1.75rem;
        }
      }

      .main-community__img {
        width: 344px;
        margin-top: 68px;

        .community-video {
          height: 196px;
        }

        img {
          width: 344px;
          height: 196px;
        }
      }
    }
  }

  .main__event {
    position: relative;

    margin: 0;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background-color: $color-light-gray;
      z-index: -1;
    }
    &__wrapper {
      padding: 24px 0 0;
      &::before {
        content: none;
      }
    }

    .main__event__title-wrapper {
      .main__title {
        font-size: 1.25rem;
        line-height: 32px;
      }
    }

    &__scroll-wrapper {
      position: relative;
      width: 100vw;
      left: -28px;
    }

    &__scroll-container {
      padding: 0 28px 12px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0;
      }
    }

    .main__event__box {
      grid-template-columns: repeat(3, minmax(300px, 1fr));
      column-gap: 16px;

      &::after {
        content: "";
        display: block;
        width: 1px;
      }
      .link-box {
        flex-direction: column;
        &__img {
          max-width: 100%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .main-page {
    background-position-x: center;
    background-size: auto 1560px;
  }

  .main-header {
    height: 670px;
    background-position: center;
    background-size: auto 100%;
    z-index: 2;
    max-height: 100%;

    &::before {
      bottom: 0;
    }

    .container {
      background-color: rgba(255, 255, 255, 0.85);
    }

    &__wrapper {
      max-width: 100%;
      width: 100%;
      padding: 72px 0 16px;
    }

    .main__title,
    &__text {
      max-width: 250px;
    }
  }

  .main-who-we-are {
    padding: 60px 0;

    .main__title {
      margin-bottom: 22px;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
    }

    &__left-block {
      margin: 0;
    }

    &__box {
      display: none;
    }

    &__box.adaptive {
      display: block;
      padding-left: 25px;
      margin-top: 20px;
    }

    &__img {
      height: 315px;
    }
  }

  .main-services {
    padding: 45px 0;
    .blur {
      height: 92%;
    }

    .main__title {
      margin-bottom: 1rem;
    }

    &__text {
      width: 100%;
    }
  }

  .why {
    padding: 32px 0;

    .why__box {
      width: 100%;

      &:last-child {
        display: none;
      }
    }

    &__item {
      &::before {
        top: 5px;
      }

      &::after {
        top: 10px;
      }
    }
  }

  .main-team {
    padding: 32px 0 15px;
    overflow: hidden;

    .container {
      padding: 0;
    }

    &__wrapper {
      margin: 0;
    }

    &__title {
      margin-bottom: 16px;
      padding: 0 16px;

      .main-team__title-text {
        display: none;
      }
    }

    &__scroll-wrapper {
      width: 100%;

      overflow: hidden;
    }

    &__scroll-container {
      padding: 0 16px 12px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        height: 0;
      }
    }

    .main-team__box {
      grid-template-columns: repeat(3, minmax(230px, 1fr));

      &::after {
        content: "";
        display: block;
        width: 1px;
      }
    }
  }

  .main-tech {
    padding: 32px 0;

    .main-tech__wrapper {
      column-gap: 5px;
      row-gap: 15px;
      grid-template: "a b c d";
      margin-left: 0;
      grid-template-columns: repeat(4, 24%);

      .techno-card__box {
        flex-direction: column;
        align-items: center;
        height: 152px;
        width: 100%;
        margin: 0;
        background-color: $color-light-gray;
        position: relative;

        .techno-card__item {
          margin-left: 0;
        }

        .techno-card__img {
          margin-left: 0;

          img {
            margin: 0;
          }
        }

        .techno-card__title {
          display: flex;
          justify-content: center;
          width: 100%;
          font-size: 0.75rem;
          line-height: 1.3;
          margin-left: 0;
          text-align: center;
          position: absolute;
          z-index: 1;
          left: 50%;
          bottom: 1rem;
          transform: translateX(-50%);
        }

        .techno-card__text-hover {
          padding: 0.5rem;
          font-size: 0.75rem;
          z-index: 2;
          margin-top: 0;
        }
      }
    }
  }

  .main-find {
    padding: 32px 0;
  }

  .main-selection {
    padding: 32px 0;

    .main__title {
      margin-bottom: 16px;
    }

    .main-selection__wrapper {
      .main-selection__box {
        .main-selection__title {
          font-size: 40px;
          line-height: 1.3;
        }

        .main-selection__text {
          font-size: 1rem;
          line-height: 1.3;
          margin-left: 1.5rem;
        }
      }
    }
  }

  .main-community {
    .main-community__wrapper {
      display: flex;
      flex-direction: column;

      .main-community__box {
        width: 100%;

        .main-community__text {
          margin-top: 1rem;
          max-width: 100%;
        }
      }

      .main-community__img {
        width: 100%;
        margin-top: 1.5rem;

        img {
          width: 100%;
          height: auto;
        }

        .blur {
          width: 288px;
          height: 164px;

          z-index: -1;
        }
      }
    }
  }

  .main__event {
    &__wrapper {
      padding: 16px 0;
    }

    .main__event__title-wrapper {
      .main__title {
        font-size: 1rem;
        line-height: 24px;
      }

      a {
        display: none;
      }
    }

    &__scroll-container {
      padding: 0 16px 12px;
    }

    .main__event__box {
      grid-template-columns: repeat(3, minmax(260px, 1fr));

      .main__event__item {
        &:not(:last-child) {
          margin-right: 1rem;
        }

        .main__event__img {
          width: 100%;

          img {
            height: 144px;
          }

          .blur {
            height: 144px;
            width: 100%;
            margin-top: 0.5rem;
          }
        }

        .main__event__title {
          margin-top: 1rem;
        }

        .main__event__text {
          margin-top: 0.1rem;
          font-size: 0.75rem;
          line-height: 140%;
        }
      }
    }
  }

  .contact-us__wrapper {
    margin-top: 0;
  }
}

@media (max-width: 575px) {
  .app__main {
    .main-services {
      padding: 32px 0;

      &__wrapper {
        gap: 40px;
      }

      &__box {
        display: flex;
        flex-direction: column;

        a {
          align-self: flex-start;
        }
      }

      &__img {
        width: 100%;

        img {
          width: 100%;
          height: auto;
          margin-left: 0;
        }

        .blur {
          width: 100%;
          height: 100%;
        }
      }

      &__title {
        margin: 16px 0 8px;
      }

      &__text {
        margin-bottom: 20px;
      }
    }

    .main-tech {
      &__wrapper {
        grid-template: "a b c";
        grid-template-columns: repeat(3, 30%);
      }
    }

    .contact-us__body .contact-us__form-label {
      margin-left: 0;
      left: 1rem;
    }
  }
}

@media (max-width: 480px) {
  .contact-us__form-confirm {
    order: 2;
  }

  .arrow-button__wrapper {
    order: 3;
  }
}

@media (max-width: 375px) {
  .app__main {
    .main-tech {
      &__wrapper {
        grid-template: "a b";
        grid-template-columns: repeat(2, 48%);
      }
    }

    .main-who-we-are {
      &__img {
        height: 126px;
      }
    }
  }
}
