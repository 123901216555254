@import '../../../../assets/style/variables';
@import '../../../../assets/style/colors';

.buttons-wrapper {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	width: 350px;
	margin-top: 10px;
	gap: 10px;

	button {
		width: 170px;
		height: 55px;
		background-color: initial;
		border: 1.5px solid rgba(255, 255, 255, 0.4);
		transition: $transition;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}

		span {
			padding-left: 30px;
			color: $color-white;
		}
	}

	@media (max-width: 480px) {
		width: 100%;
	}
}
