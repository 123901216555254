@import '../../assets/style/colors';

.team {
	padding-bottom: 1.5rem;

	.team__scroll {
		padding-bottom: 2rem;
	}
	.teams {
		margin-left: -55px;
		margin-top: 26px;
		max-width: 535px;
		width: 100%;
	}
	&__wrapper {
		display: flex;
		margin-top: 1.75rem;

		&-box {
			padding-left: 45px;
			height: 720px;
			overflow-y: scroll;

			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			&-search {
				height: 18px;
				margin-bottom: 0.5rem;

				&.hidden {
					visibility: hidden;
				}
			}
		}
		&-background {
			background-color: $color-light-gray;
			padding-top: 0.5rem;
			padding-bottom: 50px;
			&.height {
				height: 100%;
			}
		}

		&-list {
			background-color: $color-light-gray;
			width: 100%;
			height: 712px;
			padding-top: 1px;
			max-width: 487px;
			overflow: hidden;
		}

		&-filter {
			width: 100%;
			max-width: 290px;
			position: relative;
			z-index: 1;
			overflow: hidden;
		}
	}
	@media (max-width: 1440px) {
		position: relative;
		overflow: hidden;
		.teams {
			max-width: 520px;
			margin-left: -20px;
			.team__drop-buttons {
				padding-left: 30px;
			}
		}
		.team__wrapper {
			position: relative;
			justify-content: space-between;
			&-box {
				padding-left: 30px;
			}
		}
	}
	@media (max-width: 1024px) {
		.teams {
			.team__drop-buttons {
				padding-left: 15px;
			}
		}
		.team__wrapper {
			margin-top: 1.25rem;

			&-box {
				padding-left: 15px;
				max-width: 480px;
				width: 100%;
			}
		}
	}

	@media (max-width: 768px) {
		margin-bottom: 0;
		padding-bottom: 1rem;
		.container {
			padding: 0;
		}
		.teams {
			max-width: 100%;
			margin-left: 0;
		}

		& &__wrapper {
			margin-top: 0.25rem;
			flex-direction: column;
			height: auto;
			margin-top: 0;
			&-background {
				background-color: $color-white;
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				overflow-x: auto;
				padding-bottom: 0;
				&::-webkit-scrollbar {
					height: 0;
				}
			}

			&-box {
				height: auto;
				width: 100%;
				padding-left: 0;
				max-width: 100%;
				overflow-y: visible;

				&-search {
					display: none;
				}
			}

			&-list {
				height: auto;
				max-width: 100%;
				background-color: transparent;

				& > div {
					display: flex;
				}

				.team__list {
					min-height: 200px;
					width: 280px;

					&-title {
						flex-direction: column;

						span:first-child {
							padding-bottom: 0;
							font-size: 0.9rem;
						}

						span:last-child {
							padding-bottom: 0.5rem;
							font-size: 1rem;
						}
					}
				}
			}

			&-shadow {
				display: none;
			}

			&-filter {
				top: 0;

				&.show {
					z-index: 60;

					.team__filter-click {
						width: 20px;
						height: 20px;
						top: 5px;
						left: auto;
						right: 10px;
						background-color: transparent;

						&::before,
						&::after {
							content: '';
							position: absolute;
							width: 3px;
							height: 20px;
							top: 0;
							right: 0;
							bottom: 0;
							left: 0;
							margin-left: 10px;
							background-color: #d1d3d8;
						}

						&::before {
							transform: rotate(45deg);
						}

						&::after {
							transform: rotate(-45deg);
						}
					}
				}

				.team__filter-click {
					top: 0;
					width: 100px;
					height: 32px;
					margin-top: 0;
					right: 35px;
					transform: rotate(-360deg);
					background-image: none;

					&::before {
						content: 'Фильтрация';
						position: absolute;
						top: 50%;
						right: 0;
						bottom: 0;
						left: 0;
						font-size: 0.75rem;
						font-family: 'IBM';
						font-weight: normal;
						color: $color-blue;
						transform: translateY(-50%);
					}
				}
			}
		}
	}
}
