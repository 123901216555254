@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.blue-button {
	background-color: $color-blue;
	text-transform: uppercase;
	color: $color-white;
	border: none;
	transition: $transition;
	padding: 1rem 2.5rem;
	letter-spacing: 0.02rem;
	box-shadow: 0px 2px 4px rgba(62, 100, 200, 0.1), 0px 3px 8px rgba(62, 100, 200, 0.15),
		0px 4px 16px rgba(62, 100, 200, 0.4);
	&:hover {
		background-color: $color-blue-focus;
	}
}

@media (max-width: 767px) {
	.blue-button {
		padding: 16px 36px;
	}
}
