@import '../../../assets/style/colors';

.timeline__wrapper {
	position: relative;
	width: 100%;
	margin-top: 60px;

	.timeline__range {
		-webkit-appearance: none;
		height: 15px;
		width: 100%;
	}

	.timeline__range:focus {
		outline: none;
	}

	.timeline__range::-webkit-slider-runnable-track {
		width: 100%;
		height: 2px;
		background-color: rgba(55, 59, 79, 0.3);
		cursor: pointer;
	}

	.timeline__range::-moz-range-track {
		width: 100%;
		height: 2px;
		background-color: rgba(55, 59, 79, 0.3);
		cursor: pointer;
	}

	.timeline__range::-ms-track {
		width: 100%;
		height: 2px;
		background-color: rgba(55, 59, 79, 0.3);
		cursor: pointer;
	}

	.timeline__range::-webkit-slider-thumb {
		width: 10px;
		height: 10px;
		margin-top: -4px;
		border-radius: 50%;
		background-color: $color-blue;
		z-index: 3;
		cursor: pointer;
		border: none;
		-webkit-appearance: none;
	}

	.timeline__range::-moz-range-thumb {
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: $color-blue;
		z-index: 3;
		cursor: pointer;
		border: none;
	}

	.timeline__range::-ms-fill-lower {
		background-color: $color-blue;
	}

	.timeline__range::-ms-fill-upper {
		background-color: rgba(55, 59, 79, 0.3);
	}
	.timeline__range::-ms-thumb {
		box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
		border: 1px solid #000000;
		height: 36px;
		width: 16px;
		border-radius: 3px;
		background-color: $color-white;
		cursor: pointer;
	}
	.timeline__range:focus::-ms-fill-lower {
		background-color: $color-blue;
	}
	.timeline__range:focus::-ms-fill-upper {
		background-color: rgba(55, 59, 79, 0.3);
	}
	.selector {
		position: absolute;
		top: 7px;
		height: 2px;
		background-color: $color-blue;
		cursor: pointer;
	}
	.output {
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 92px;
		height: 29px;
		bottom: 30px;
		left: 25px;
		background-color: #eff3fd;
		border-radius: 50px;
		font-family: 'IBM';
		font-weight: bold;
		font-size: 0.75rem;
		line-height: 20px;
		color: $color-blue;
	}
	.segment__wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		position: absolute;
		bottom: 0;
		height: 5px;
		width: 100%;
		pointer-events: none;
		transform: translateY(-200%);
	}
	.segment {
		position: relative;
		height: 8px;
		width: 2px;
		background-color: rgba(55, 59, 79, 0.3);

		&:first-child span {
			left: -30px;
		}

		span {
			top: 12px;
			left: 8px;
			position: absolute;
			white-space: nowrap;
			font-family: 'IBM';
			font-size: 0.75rem;
			line-height: 20px;
			color: $color-purple;
			opacity: 0.8;
		}
	}

	@media (max-width: 767px) {
		margin-top: 0;
		height: 60px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		.output {
			position: static;
			margin: 0 auto;
		}
		.timeline__range {
			height: 24px;
		}
		.selector {
			bottom: 11px;
			top: auto;
		}
	}
}
