@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.oval-button {
	height: 38px;
	padding: 8px 16px;
	border: none;
	border-radius: 50px;
	background-color: rgba(55, 59, 79, 0.14);
	font-family: 'IBM';
	font-size: 1rem;
	line-height: 140%;
	color: $color-main;
	cursor: pointer;
	transition: $transition;

	&:hover {
		background-color: #373b4f;
		color: $color-white;
	}
	&:active {
		opacity: 0.8;
	}
}

.oval-button__checkbox {
	display: none;

	&:checked + .oval-button {
		background-color: #373b4f;
		color: $color-white;
	}
}
