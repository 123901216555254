@import '../../../assets/style/colors';

.services-page {
	.pages-header {
		.container {
			margin-top: -20px;
		}
		&__image {
			background-position: left !important;
		}
	}

	.brands {
		margin-top: -80px;
		border-top: 1px solid #373b4f1c;
	}

	.h1 {
		text-transform: uppercase;
	}

	.blur {
		position: absolute;
		z-index: -1;
		top: 0.5rem;
		width: 100%;
	}
	.mobile-img {
		display: none;
	}

	.services-page__image {
		align-self: flex-start;
		position: relative;

		img {
			width: 100%;
		}
	}

	&__text {
		font-family: 'IBM';
		font-size: 1rem;
		line-height: 140%;
		color: $color-purple;
	}

	&__subtitle {
		font-family: 'Commissioner';
		font-weight: bold;
		font-size: 1.5rem;
		line-height: 32px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $color-main;
	}

	.command-block {
		padding: 110px 0 80px;

		.container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 30px;
			row-gap: 25px;
		}

		&__title {
			max-width: 365px;
			letter-spacing: 0.11rem;
		}

		&__specialization-areas {
			grid-column-start: 1;
			grid-column-end: 3;
		}

		&__text {
			margin-top: 25px;
		}

		.specialization-areas__block {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			grid-gap: 30px;
			margin-top: 25px;
		}
	}

	.outsourcing-block {
		padding: 80px 0;

		.container {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			column-gap: 30px;
		}

		&__subtitle {
			margin-bottom: 0.6rem;
		}

		&__text {
			width: 640px;
			margin-top: 25px;
		}

		.outsourcing-block__info-block {
			display: flex;
			flex-direction: column;
		}

		.services-page__image {
			margin-top: 45px;
			line-height: 2.2;
		}

		&__technologies {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;

			&-block {
				margin-bottom: 10px;
				display: grid;
				grid-template-columns: repeat(5, 1fr);
				grid-gap: 19px;

				.technologies__item {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: space-between;
					width: 112px;
					height: 112px;
					padding: 10px;
					background-color: $color-light-gray;

					&-icon-wrapper {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 100%;
						height: 100%;
					}

					&-title {
						font-family: 'IBM';
						font-size: 0.75rem;
						line-height: 20px;
						color: $color-main;
					}
				}

				.technologies-block__last-item {
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: 'IBM';
					font-size: 1rem;
					line-height: 140%;
					color: $color-purple;
				}
			}
		}
	}

	.create-team__block {
		padding: 40px 0;
	}

	.equipment-design__block {
		padding: 80px 0 120px;

		.container {
			display: flex;
			justify-content: space-between;
		}

		.equipment-design-title {
			margin-bottom: 25px;
		}

		.equipment-design__info-block {
			width: 530px;

			.services-page__text-bold {
				font-weight: bold;
				padding: 7px 0;
				width: 550px;
			}
		}
	}

	.selection-process__block {
		padding: 125px 0 160px;

		.selection-process__items-block {
			display: flex;
			justify-content: space-between;
			margin-top: 60px;

			.selection-process__item {
				display: flex;
				flex-direction: column;
				align-items: center;
				max-width: 290px;
				position: relative;

				&::before,
				&::after {
					content: '';
					position: absolute;
					width: 4px;
					height: 15px;
					background-color: #eff3fe;
					right: -60%;
				}

				&::before {
					bottom: 1.45rem;
					transform: rotate(45deg) translateX(50%);
				}

				&::after {
					bottom: 1.9rem;
					transform: rotate(-45deg) translateX(50%);
				}

				.selection-process__item-icon {
					height: 165px;
					width: 150px;
					display: flex;
					justify-content: center;
					align-items: center;
				}

				.selection-process__item-title {
					font-family: 'Commissioner';
					font-weight: bold;
					font-size: 1.5rem;
					line-height: 32px;
					display: flex;
					letter-spacing: 0.04em;
					text-transform: uppercase;
					color: $color-blue;
				}

				&:last-child {
					.selection-process__item-title {
						margin-top: -1rem;
					}

					.selection-process__item-icon {
						margin-left: -5rem;
					}

					&::before,
					&::after {
						content: none;
					}
				}
			}
		}
	}

	.contact-us__wrapper {
		position: relative;
		z-index: 0;
	}

	@media (max-width: 1440px) {
		& {
			.pages-header {
				.container {
					margin-top: -75px;
				}
			}
			.services-page__text {
				margin-top: 15px;
				font-size: 0.875rem;
				line-height: 140%;
			}

			.command-block {
				padding: 80px 0 40px;

				&__specialization-areas {
					.command-block__subtitle {
						display: none;
					}
				}

				.specialization-areas__block {
					grid-template-columns: repeat(3, 1fr);
					grid-gap: 20px;
					margin-top: 10px;
				}
			}

			.outsourcing-block {
				padding: 80px 0 50px;

				&__info-block {
					padding-bottom: 110px;
				}

				&__technologies {
					margin-top: 11.95rem;
				}

				.container {
					column-gap: 20px;
				}

				&__text {
					width: 465px;
				}

				&__technologies-block {
					display: grid;
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 16px;

					.technologies__item {
						width: 104px;
						height: 104px;
						padding: 10px;

						&:nth-child(13) {
							display: none;
						}
					}

					.technologies-block__last-item {
						grid-column-start: 1;
						grid-column-end: 5;
					}
				}
			}

			.create-team__block {
				padding: 20px 0;

				.x-line__x-logo {
					right: 391px;
				}

				.x-line__x-logo-substrate {
					width: 400px;
				}

				.x-line__block-title {
					width: 400px;
					font-size: 1.5rem;
					line-height: 32px;
					letter-spacing: 0.04em;
				}

				.main-team__button {
					margin-left: 260px;
				}
			}

			.equipment-design__block {
				padding: 30px 0;

				.equipment-design-title {
					white-space: nowrap;
				}

				.equipment-design__text {
					max-width: 485px;
					margin-right: 1rem;

					.services-page__text-bold {
						width: auto;
					}
				}

				.services-page__image {
					margin-top: 63px;
				}
			}

			.selection-process__block {
				padding: 130px 0 60px;

				.selection-process__items-block {
					padding-right: 20px;
					margin-top: 70px;

					.selection-process__item {
						width: 180px;

						&::before {
							transform: rotate(45deg) translateX(50%);
							right: -15%;
							bottom: 1rem;
						}

						&::after {
							transform: rotate(-45deg) translateX(50%);
							right: -15%;
							bottom: 1.4rem;
						}

						&:last-child {
							.selection-process__item-icon {
								margin-left: 0;
							}
						}

						.selection-process__item-icon {
							width: 130px;
							height: 135px;

							img {
								width: 100%;
								height: auto;
							}
						}

						.selection-process__item-title {
							margin-top: -20px;
							font-size: 1rem;
							line-height: 24px;
							letter-spacing: 0.04em;
							height: 100%;
							align-items: center;
						}
					}
				}
			}
		}
	}

	@media (max-width: 1024px) {
		& {
			.pages-header {
				.container {
					margin-top: 0;
				}
			}
			.brands {
				background-color: rgba(255, 255, 255, 1);
				margin-top: 0;

				&__blur {
					backdrop-filter: none;
				}
			}

			.command-block {
				padding: 60px 0 40px;

				.command-block__info-block {
					width: 340px;
				}

				.command-block__title {
					white-space: nowrap;
				}

				.services-page__text {
					margin-top: 40px;
				}

				.services-page__image {
					margin-top: 80px;
				}

				.specialization-areas__block {
					grid-template-columns: repeat(2, 1fr);
					grid-gap: 5px 10px;
					margin-top: 0;
				}
			}

			.outsourcing-block {
				padding: 85px 0 40px;

				.container {
					display: flex;
					flex-direction: column;
				}

				.outsourcing-block__info-block {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					padding: 0;
				}

				.outsourcing-block__text {
					margin-top: 25px;
					width: 344px;
					grid-column: 1;
				}

				.services-page__image {
					margin-top: 25px;
					.blur {
						height: 92%;
					}
				}

				.services-page__subtitle {
					font-size: 1.25rem;
					margin-top: 15px;
				}

				&__technologies {
					margin-top: 1rem;
				}

				.outsourcing-block__technologies-block {
					margin-top: 20px;
					grid-template-columns: repeat(5, 1fr);
					grid-gap: 40px;

					.technologies__item:nth-child(13) {
						display: flex;
					}

					.technologies-block__last-item {
						grid-column-start: 4;
						grid-column-end: 4;
					}
				}
			}

			.create-team__block {
				padding: 20px 0;

				.x-line__block-title {
					width: 300px;
				}

				.main-team__button {
					margin-left: 190px;

					span {
						white-space: nowrap;
					}
				}

				.x-line__x-logo {
					right: 50%;
					transform: translateX(50%);
					width: 290px;

					&-substrate {
						right: 0;
						width: 38%;

						&:after {
							left: -60%;
							border-top: 120px solid transparent;
							border-right: 120px solid $color-white;
							border-bottom: 120px solid transparent;
							border-left: 120px solid transparent;
						}
					}
				}
			}

			.equipment-design__block {
				padding: 40px 0 80px;

				.equipment-design__info-block {
					align-items: flex-start;
					width: 100%;
					max-width: 370px;
				}

				.equipment-design__text {
					width: 100%;
					max-width: 344px;

					.services-page__text-bold {
						width: 100%;
					}
				}
			}

			.selection-process__block {
				display: none;
			}
		}
	}

	@media (max-width: 900px) {
		.create-team__block {
			.x-line__x-logo {
				&-substrate {
					width: 39%;

					&:after {
						left: -64%;
					}
				}
			}
		}
	}

	@media (max-width: 767px) {
		.services-page__text {
			font-size: 0.75rem;
			line-height: 140%;
		}

		.command-block {
			padding: 40px 0;

			.container {
				display: flex;
				flex-direction: column;

				.command-block__info-block {
					display: flex;
					flex-direction: column;
					width: 100%;

					.command-block__title {
						white-space: pre-wrap;
					}

					.services-page__text {
						margin-top: 10px;
					}
				}

				.services-page__image {
					display: none;
					&.mobile-img {
						display: block;
						margin: 1rem 0 1rem 0;
					}
				}

				.blur {
					display: none;
				}

				.specialization-areas__block {
					grid-template-columns: repeat(4, 1fr);
					grid-gap: 10px;
					margin-top: -10px;
				}
			}
		}

		.outsourcing-block {
			.container {
				display: flex;
				flex-direction: column;
			}

			.outsourcing-block__info-block {
				display: flex;
				flex-direction: column;
			}

			.outsourcing-block__text {
				margin-top: 15px;
				width: 100%;
			}

			.outsourcing-block__title {
				order: -2;
			}

			.services-page__image {
				margin: 15px 0 0 0;
				order: -1;
			}

			.outsourcing-block__subtitle {
				display: none;
			}

			.outsourcing-block__technologies-block {
				display: grid;
				grid-template-columns: repeat(4, 1fr);
				grid-gap: 7px;
				margin-top: 10px;

				.technologies__item {
					width: 100%;
					height: 110px;
					padding: 5px;
					background-color: $color-white;

					&:nth-child(13) {
						display: flex;
					}
				}

				.technologies-block__last-item {
					grid-column-start: 2;
					grid-column-end: 3;

					span {
						margin-right: -100px;
					}
				}
			}
		}

		.create-team__block {
			.main-team__button {
				margin: 10px 0 0 0px;

				span {
					line-height: 25px;
				}
			}
		}

		.equipment-design__block {
			.container {
				flex-direction: column;
				justify-content: flex-start;
			}
			.services-page__image {
				margin-top: 10px;
			}
			.equipment-design__info-block {
				width: 100%;
				flex-direction: column-reverse;

				.adapive-img-wrapper {
					display: block;

					img {
						height: 100%;
					}

					.services-page__image {
						display: block;
						margin: 10px 0 0 0;
					}

					.blur {
						display: block;
					}
				}

				.equipment-design-title {
					white-space: pre-wrap;
				}

				.equipment-design__text {
					margin-top: 12px;
					width: 100%;
					max-width: 100%;

					.services-page__text-bold {
						width: 100%;
					}
				}
			}
		}

		.selection-process__block {
			display: none;
		}
	}
}

@media (max-width: 575px) {
	.services-page {
		.command-block {
			padding: 32px 0;

			.container {
				.specialization-areas__block {
					grid-template-columns: repeat(3, 1fr);
				}
			}
		}

		.outsourcing-block {
			padding: 32px 0;

			.container {
				.outsourcing-block__technologies-block {
					grid-template-columns: repeat(3, 1fr);
				}
			}
		}

		.create-team__block {
			padding: 16px 0;
		}

		.equipment-design__block {
			padding: 32px 0;

			.equipment-design-title {
				white-space: normal;
			}
		}
	}
}

@media (max-width: 450px) {
	.services-page {
		.command-block {
			.container {
				.specialization-areas__block {
					grid-template-columns: repeat(2, 1fr);
				}
			}
		}
	}
}
