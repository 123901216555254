@import '../../assets/style/colors';
@import '../../assets/style/variables';

.specializations {
	display: grid;
	grid-template: 'a b c d';
	column-gap: 10px;
	row-gap: 5px;
	margin-top: 10px;
}

.specializations-item {
	label {
		width: 290px;
		position: relative;
		display: block;
		padding-left: 25px;
		cursor: pointer;
		span {
			transition: $transition;
		}
		&:hover span {
			color: rgba(55, 59, 79, 0.6);
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 3px;
			width: 16px;
			height: 16px;
			margin-right: 15px;
			border: 1px solid rgba(55, 59, 79, 0.4);
			border-radius: 2px;
			background-color: $color-white;
			cursor: pointer;
		}
	}
	input {
		display: none;

		&:checked + label::before {
			border: none;
			background-color: $color-blue;
		}

		&:checked + label::after {
			content: '';
			position: absolute;
			left: 1px;
			top: 6px;
			left: 9px;
			width: 1.7px;
			height: 10px;
			background-color: $color-white;
			transform: rotate(40deg);
			pointer-events: none;
		}

		&:checked + label span::after {
			content: '';
			position: absolute;
			left: 1px;
			top: 10px;
			left: 4px;
			width: 1.7px;
			height: 4px;
			background-color: $color-white;
			transform: rotate(-45deg);
			pointer-events: none;
		}
	}

	@media (max-width: 769px) {
		label {
			width: 100%;
		}
	}
}
