@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.footer {
	background-color: $color-light-gray;
	flex-shrink: 0;
	color: $color-purple;
	position: relative;
	z-index: 50;
	.scroll-it-up {
		position: fixed;
		right: 3rem;
		bottom: 3rem;
		background-color: $color-blue;
		width: 50px;
		height: 50px;
		border: 0;
		border-radius: 50%;
		opacity: 0.5;
		cursor: pointer;
		transition: $transition;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			margin: -20px;
			width: 40px;
			height: 40px;
			transition: $transition;

			@include bg-image('icons/arrow-up.svg');
		}
		&:hover {
			opacity: 1;
			background-size: 90%;
			border-radius: 25%;
			&::before {
				margin: -15px;
				width: 30px;
				height: 30px;
			}
		}
	}
	&__wrapper {
		display: flex;
		justify-content: space-between;
		height: 100%;

		&-item {
			padding: 47px 0 40px;
			display: flex;

			&:first-child {
				height: 100%;
				flex-direction: column;
				justify-content: space-between;
			}

			&:last-child {
				max-width: 750px;
				width: 100%;
				justify-content: space-between;
			}
		}
	}

	&__logo {
		line-height: 4;
	}

	&__text {
		display: flex;
		flex-direction: column;
	}

	&__title {
		margin-bottom: 1.2rem;
	}

	&__box {
		letter-spacing: 0.04em;
		width: 100%;

		&-item {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
		}
	}

	&__nav {
		max-width: 220px;

		&-wrapper {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
		}

		&-item:not(:last-child) {
			margin-bottom: 12px;
		}

		&-link {
			line-height: 20px;
			color: $color-purple;

			&:hover {
				color: $color-blue;
			}
		}
	}

	&__social {
		max-width: 225px;

		.footer__title {
			margin-bottom: 0.9rem;
		}

		.footer__nav-link {
			display: flex;
			align-items: center;

			.img__wrapper {
				width: 28px;
				height: 28px;
				transition: $transition;
				position: relative;
				margin-right: 0.5rem;
				display: flex;
				justify-content: center;
				align-items: center;

				border-radius: 5px;

				.blur {
					position: absolute;
					width: 20px;
					height: 19px;
					z-index: 0;
					top: 17%;
					-moz-filter: blur(3px);
					-o-filter: blur(3px);
					-ms-filter: blur(3px);
					-webkit-filter: blur(3px);
					filter: blur(3px);
					opacity: 0.7;
					transition: $transition;
				}

				img {
					position: relative;
					z-index: 1;
				}
			}
		}
	}

	&__contact {
		&-address {
			max-width: 310px;
		}

		&-link {
			display: flex;
			flex-direction: column;
			margin-top: 3rem;
		}
	}

	@media (max-width: 1440px) {
		& {
			&__nav-wrapper {
				font-size: 0.875rem;
			}

			&__wrapper-item:last-child {
				justify-content: flex-end;
				max-width: 570px;
			}

			&__box {
				letter-spacing: 0.04em;
				margin-right: 0;

				&:last-child {
					margin-right: 0;
				}

				.text2 {
					font-size: 0.875rem;
					line-height: 140%;
				}
			}

			&__social,
			&__nav {
				max-width: 180px;
			}

			&__contact-link {
				margin-top: 16px;
			}
		}
	}

	@media (max-width: 1024px) {
		& {
			height: 367px;

			.text3 {
				text-align: end;
			}

			&__wrapper {
				flex-wrap: wrap;
				padding-right: 20px;

				&-item {
					padding: 0;
				}

				&-item:last-child {
					justify-content: space-between;
					margin-top: 45px;
					width: 100%;
					max-width: 100%;
				}

				&-item:first-child {
					flex-direction: row;
					width: 100%;
					height: 20px;
					order: 1;
				}
			}

			.footer__social {
				max-width: 200px;
				width: 100%;
			}

			&__box {
				letter-spacing: 0.04em;
				margin-right: 20px;

				&:first-child {
					width: 100%;
					max-width: 100%;
				}

				&:last-child {
					margin-right: 0;
					width: 100%;
					max-width: 170px;
				}

				.text2 {
					font-size: 0.875rem;
					line-height: 140%;
				}
			}
		}
	}

	@media (max-width: 767px) {
		background-color: $color-light-gray;
		.scroll-it-up {
			width: 40px;
			height: 40px;
			right: 1rem;
			bottom: 1rem;
			&::before {
				margin: -15px;
				width: 30px;
				height: 30px;
			}

			&:hover {
				&::before {
					margin: -10px;
					width: 20px;
					height: 20px;
				}
			}
		}
		.footer__wrapper {
			.footer__title {
				margin-bottom: 0.6rem;
			}

			.text3 {
				text-align: start;
			}
		}
	}
}

@media (max-width: 480px) {
	.footer {
		height: auto;

		.footer__wrapper {
			flex-direction: column;
			flex-wrap: nowrap;
			padding: 0 24px 24px;

			&-item {
				flex-direction: column;
				height: auto;

				&:first-child {
					gap: 8px;

					.footer__logo {
						max-height: 40px;
					}
				}
			}
		}

		&__box {
			margin-right: 0;
			margin-bottom: 2rem;
			&:last-child {
				max-width: 400px;
			}
		}

		&__social {
			.footer__nav-wrapper {
				flex-direction: row;
				height: auto;

				.social-title {
					display: none;
				}
			}
		}
		&__contact {
			&-address {
				max-width: 100%;
				width: 100%;
			}
			&-link {
				.footer__nav-link:first-child {
					margin-bottom: 0.5rem;
				}
			}
		}
	}
}
