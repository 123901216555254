@import '../../../assets/style/variables';
@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.company-page {
	.h1,
	.h2 {
		text-transform: uppercase;
		letter-spacing: 2px;
	}

	.blur {
		position: absolute;
		top: 0.5rem;
		min-width: 100%;
		width: 100%;
		z-index: -1;
	}
	&__popup {
		.contact-us__form-wrapper {
			margin-top: 40px;
		}
	}

	.company-page__about-us {
		display: flex;
		margin-top: 7.5rem;

		.about-us__info {
			.about-us__title {
				margin-top: 10px;
			}

			.about-us__text {
				margin-top: 30px;
			}

			.about-us__items-block {
				margin-top: 40px;

				.about-us__item {
					position: relative;
					margin-top: 30px;
					padding-left: 25px;
					font-family: 'IBM';
					font-size: 1rem;
					line-height: 140%;
					color: $color-purple;

					span {
						&::after {
							content: '';
							position: absolute;
							top: 10px;
							left: 0px;
							width: 8px;
							border-top: 2px solid $color-blue;
							transform: rotate(45deg);
							transition: $transition;
						}

						&::before {
							content: '';
							position: absolute;
							top: 14.5px;
							left: 0px;
							width: 8px;
							border-top: 2px solid $color-blue;
							transform: rotate(-45deg);
							transition: $transition;
						}
					}
				}
			}
		}

		.about-us__img {
			position: relative;
			margin-left: 50px;
		}
	}

	.our-partners {
		height: 410px;
		margin-top: 120px;
		padding-top: 80px;

		.partners__list {
			margin-top: 65px;
			display: flex;
			justify-content: space-between;
		}
	}

	.company-page__team {
		.team-title {
			margin-top: 80px;
		}

		.team-wrapper {
			margin-top: 2.8rem;
			display: grid;
			grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
			grid-auto-rows: 370px;
			gap: 30px;

			.team-item {
				position: relative;
				overflow: hidden;
				background-color: $color-transparent-gray;

				.team-photo {
					min-height: 100%;
					width: 100%;
					object-fit: cover;
				}

				.team-info {
					position: absolute;
					right: 0;
					bottom: 0;
					left: 0;
					height: 100px;
					width: 100%;
					padding: 20px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					background-color: rgba(255, 255, 255, 0.7);
					-moz-backdrop-filter: blur(16px);
					-o-backdrop-filter: blur(16px);
					-ms-backdrop-filter: blur(16px);
					-webkit-backdrop-filter: blur(16px);
					backdrop-filter: blur(16px);
					z-index: 10;

					.team-info__name {
						font-size: 1rem;
						line-height: 1.3;
					}
					.team-info__file {
						margin-top: 5px;
						font-size: 1rem;
					}
				}
				&__send-box {
					background-color: #4c4f61;
					grid-column: 2/5;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					align-items: center;
					position: relative;

					&::before {
						content: '';
						position: absolute;
						width: 100%;
						height: 100%;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						opacity: 0.1;

						@include bg-image('backgrounds/X-big-services.svg', center, cover);
					}
					.main-team__button {
						margin: 2.3rem 0 3.75rem;
					}
				}
				&__send-text {
					color: $color-white;
					font-size: 3rem;
					text-transform: uppercase;
					font-weight: 600;
					text-align: center;
					max-width: 460px;
				}
			}
		}
	}

	.where-we-r {
		.where-we-r__title {
			margin-top: 180px;
		}

		.where-we-r__text {
			line-height: 140%;
			margin-top: 25px;
			max-width: 740px;
		}
	}

	.contact-us__wrapper {
		margin-top: 80px;
	}

	@media (max-width: 1440px) {
		& {
			.company-page__about-us {
				margin-top: 55px;

				.about-us__info {
					.about-us__text {
						margin-top: 15px;
						width: 450px;
						font-size: 0.875rem;
					}

					.about-us__items-block {
						margin-top: 0px;
					}

					.about-us__items-block .about-us__item {
						margin-top: 20px;
						font-size: 0.875rem;
					}
				}

				.about-us__img {
					min-height: 254px;
					margin-left: 60px;
					margin-top: 60px;
				}
			}

			.our-partners {
				margin-top: 80px;
				padding-top: 60px;
				height: 280px;

				.partners__list {
					margin-top: 40px;

					img {
						max-width: 195px;
						max-height: 40px;
					}
				}
			}

			.company-page__team {
				.team-title {
					margin-top: 40px;
				}

				.team-wrapper {
					margin-top: 25px;
					gap: 16px;
					grid-template-columns: repeat(3, 1fr);
					grid-auto-rows: auto;

					.team-item {
						.team-photo {
							width: 100%;
							height: auto;
							min-height: auto;
						}

						.team-info {
							height: 110px;
							.team-info__name {
								font-size: 16px;
							}

							.team-info__file {
								font-size: 14px;
							}
						}
						&__send-box {
							grid-column: 1/4;
							min-height: 300px;
							align-self: center;
							max-width: 625px;
							width: 100%;
							margin: 0 auto;
						}
					}
				}
			}

			.where-we-r {
				.where-we-r__title {
					margin-top: 120px;
				}

				.where-we-r__text {
					margin-top: 20px;
					width: 600px;
					font-size: 0.875rem;
				}
			}

			.contact-us__wrapper {
				margin-top: 40px;
			}
		}
	}

	@media (max-width: 1024px) {
		& {
			.company-page__about-us {
				align-items: flex-start;

				.about-us__info {
					width: 100%;

					.about-us__text {
						width: 100%;
					}
				}

				.about-us__img {
					width: 100%;
					margin-left: 30px;
					margin-top: 60px;
					min-height: auto;

					img {
						width: 100%;
						height: auto;
					}
				}
			}

			.our-partners {
				margin-top: 17px;
			}

			.company-page__team {
				.team-title {
					margin-top: 20px;
				}

				.team-wrapper {
					margin-top: 25px;
				}
			}
		}
	}

	@media (max-width: 767px) {
		& {
			.company-page__about-us {
				margin-top: 25px;

				.about-us__info {
					min-width: 100px;
				}

				.about-us__text {
					width: 288px;
					margin-top: 10px;
				}

				.about-us__items-block {
					margin-top: 35px;
				}
			}
		}

		.company-page__about-us .about-us__info {
			min-width: 100px;
		}

		.our-partners {
			margin-top: 15px;
			height: 200px;

			.partners__list {
				margin-top: 20px;

				img {
					width: 120px;
				}
			}
		}

		.company-page__team {
			.team-wrapper {
				margin-top: 25px;
				grid-template-columns: repeat(2, 1fr);
				.team-item__send-box {
					grid-column: 1/3;
					.team-item__send-text {
						font-size: 2rem;
						max-width: 390px;
					}
				}
			}
		}

		.where-we-r {
			.where-we-r__title {
				margin-top: 90px;
				width: 288px;
			}

			.where-we-r__text {
				margin-top: 15px;
				width: 100%;
			}
		}

		.contact-us__wrapper {
			margin-top: 20px;
		}
	}
}

@media (max-width: 575px) {
	.company-page {
		.container {
			.company-page__about-us {
				flex-direction: column;
				.about-us__img {
					margin-left: 0;
					margin-top: 2rem;
				}
			}
			.our-partners {
				overflow: hidden;
				.partners__list {
					height: 65px;
					gap: 20px;
					overflow-x: auto;
					&::-webkit-scrollbar {
						height: 0;
					}
				}
			}
		}
	}
}

@media (max-width: 500px) {
	.company-page {
		.container {
			.company-page__team {
				.team-wrapper {
					grid-template-columns: 1fr;
					.team-item__send-box {
						grid-column: auto;
						padding: 10px;
					}
				}
			}
		}
	}
}
