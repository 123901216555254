@import '../../assets/style/colors';
@import '../../assets/style/variables';
@import '../../assets/style/mixins';

.global-messages {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 80px;
	opacity: 0;
	transition: $transition;
	z-index: -1000;
	&.visible {
		opacity: 1;
		z-index: 1000;
	}
}

.global-message {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: 'IBM';
	font-size: 1rem;
	line-height: 1.3;
	color: $color-white;

	span {
		position: relative;
		&::before {
			content: '';
			position: absolute;
			width: 24px;
			height: 24px;
			left: -36px;
			background-size: contain;
		}
	}

	&.error {
		background-color: $color-red;
		span {
			&::before {
				@include bg-image('icons/load/Fail.svg');
			}
		}
	}

	&.success {
		background-color: $color-green;
		span {
			&::before {
				@include bg-image('icons/load/Success.svg');
			}
		}
	}
}
