.app__main {
	flex: auto;
	margin-top: 75px;
	@media (max-width: 1440px) {
		margin-top: 89px;
	}
	@media (max-width: 1024px) {
		margin-top: 48px;
	}

	@media (max-width: 767px) {
		margin-top: 32px;
	}
}
