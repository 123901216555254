@import '../../../assets/style/variables';
@import '../../../assets/style/colors';
@import '../../../assets/style/mixins';

.career-page {
	.h1 {
		text-transform: uppercase;
		letter-spacing: 2px;
	}

	.h3 {
		text-transform: uppercase;
	}

	.blur {
		position: absolute;
		top: 0.5rem;
		z-index: -1;
	}

	&__specializations {
		&-title {
			margin-top: 8.5rem;
		}

		.techno-card__wrapper {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 33px;
			margin-top: 35px;
		}

		.send-resume-buttom {
			display: none;
		}

		.techno-card__box {
			padding: 30px 90px 30px 30px;
			width: 635px;
			height: 190px;
			background-color: $color-light-gray;
			border: 1px solid rgba(55, 59, 79, 0);
			box-shadow: 0px 2px 10px rgba(55, 59, 79, 0);
			margin: 0;
			box-sizing: border-box;
			cursor: pointer;
			border: 1px solid transparent;
			transition: $transition;
			position: relative;
			z-index: 0;
			overflow: hidden;

			.techno-card__title {
				letter-spacing: 1px;
			}

			.blue-button {
				display: block;
				width: 375px;
				margin-top: 30px;
				opacity: 0;
				transition: $transition;
				position: absolute;
				z-index: 1;
				top: 100%;
			}

			&:hover {
				background-color: $color-white;
				border: 1px solid $color-transparent-gray;
				max-width: 639px;
				box-shadow: 0px 2px 10px $color-transparent-gray;

				.techno-card__text {
					opacity: 0;
				}

				.blue-button {
					opacity: 1;
					top: 50%;
					transform: translateY(-50%);
				}
			}
		}
	}

	.work-format {
		margin-top: 14.4rem;

		&__image-block-wrapper {
			margin-top: 50px;
			display: flex;
			justify-content: space-between;
		}

		&__image-item {
			position: relative;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}

		&__img {
			overflow: hidden;

			img {
				width: 100%;
			}
		}

		&__info {
			position: absolute;
			height: 135px;
			width: 100%;
			padding: 25px 245px 30px 30px;
			background-color: rgba(255, 255, 255, 0.8);
			backdrop-filter: blur(16px);
		}

		&__info-text {
			margin-top: 10px;
		}
	}

	.become-part-of-the-ship {
		margin-top: 155px;
	}

	&__questions-block {
		display: flex;
		margin-top: 80px;

		.questions-block__info {
			width: 780px;
			padding-right: 150px;

			.questions-block__body {
				margin-top: 60px;

				.questions-block__question {
					position: relative;
					padding-left: 30px;
					margin-bottom: 30px;
					transition: $transition;

					.career-question {
						max-width: 510px;
						cursor: pointer;
					}

					&::after {
						content: '';
						position: absolute;
						top: 10px;
						left: 0px;
						width: 8px;
						border-top: 2px solid $color-blue;
						transform: rotate(45deg);
						transition: $transition;
					}

					&::before {
						content: '';
						position: absolute;
						top: 10px;
						left: 4px;
						width: 8px;
						border-top: 2px solid $color-blue;
						transform: rotate(-45deg);
						transition: $transition;
					}

					& input {
						display: none;

						&:checked + .career-answer {
							display: block;
							margin-top: 8px;

							& + p {
								&::after {
									top: 10px;
									left: 0px;
									transform: rotate(-45deg);
								}

								&::before {
									top: 10px;
									left: 4px;
									transform: rotate(45deg);
								}
							}
						}
					}

					.career-answer {
						display: none;
						margin-top: 8px;
						margin-top: -30%;
						max-width: 580px;
						transition: $transition;
					}
				}
			}
		}

		.questions-block__img {
			position: relative;
			margin-top: 75px;
			max-height: 688px;
		}
	}

	.telegram-contact {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 120px;
		padding: 30px;
		margin-top: 50px;
		background-color: $color-light-gray;

		.h2 {
			text-transform: uppercase;
			letter-spacing: 1.2px;
		}

		button {
			width: 270px;
			padding: 0.9rem 4rem;

			span {
				display: block;
				padding-left: 25px;
				background-position: left;

				@include bg-image('icons/telegram.svg');
			}

			&::before,
			&::after {
				display: none;
			}
		}
	}

	.contact-us__wrapper {
		margin-top: 95px;
		background-color: #4d5062;

		position: relative;

		.contact-us__contacts {
			display: flex;
			flex-direction: column;
			height: 100%;
			&-social {
				margin-top: auto;
			}
		}
	}

	@media (max-width: 1440px) {
		& {
			.career-page__specializations {
				margin-top: 4rem;

				&-title {
					margin-top: 20px;
				}
			}

			.techno-card__wrapper {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 10px;
				margin: 15px 0 40px;

				.techno-card__box {
					padding: 0 0 0 20px;
					max-width: 465px;
					height: 100px;
					background-color: transparent;
					pointer-events: none;

					.techno-card__title {
						letter-spacing: 1px;
					}

					.techno-card__text {
						width: 350px;
						font-size: 0.875rem;
						margin-top: 0.25rem;
					}

					.techno-card__img {
						height: 100%;
						min-width: 100px;

						img {
							height: 100px;
						}
					}
				}
			}

			.send-resume-buttom {
				display: flex;
				justify-content: center;
			}

			.work-format {
				margin-top: 110px;

				.work-format__image-block-wrapper {
					margin-top: 25px;
					display: flex;
					justify-content: space-between;

					.work-format__image-item {
						&:last-child {
							margin-left: 12px;
						}

						.work-format__info {
							padding: 20px;
							height: 90px;
						}

						.work-format__img {
							box-shadow: 0px 2px 4px rgba(55, 59, 79, 0.05), 0px 3px 8px rgba(55, 59, 79, 0.08),
								0px 4px 16px $color-transparent-gray;
						}

						.blur {
							display: none;
						}
					}
				}
			}

			.career-answer {
				font-size: 0.875rem;
			}

			.become-part-of-the-ship {
				margin-top: 50px;
			}

			.career-page__questions-block {
				margin-top: 88px;

				.h3 {
					letter-spacing: 0.04em;
				}

				.questions-block__info {
					width: 780px;
					padding-right: 10px;
				}

				.questions-block__body {
					margin-top: 40px;
				}

				.questions-block__img {
					height: 500px;
					margin-top: 75px;

					img {
						height: 100%;
					}
				}
			}

			.contact-us__wrapper {
				margin-top: 130px;
			}

			.telegram-contact {
				display: none;
			}

			.career-page__footer {
				margin-top: 170px;
				background-color: #4d5062;
				padding: 60px 0 65px 0;
			}

			.career-page__footer-info {
				.text-block {
					.career-page__footer-title {
						width: 350px;
					}

					.career-page__footer-text {
						width: 300px;
					}
				}

				.buttons-block {
					margin-top: 70px;
				}
			}

			.career-page__footer-form {
				.contact-us__form input {
					max-width: 216px;
					min-width: 100%;
				}

				.contact-us__form-label {
					margin-left: -204px;
				}

				.contact-us__form-label--idea {
					margin-left: -450px;
				}

				.contact-us__form-attach {
					margin-left: 0px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		& {
			.career-page__specializations {
				.techno-card__wrapper {
					margin-top: 5px;
				}
				.techno-card__box {
					height: 110px;
					max-width: auto;
					width: auto;
					padding: 0;

					.techno-card__img {
						width: 100%;
						margin-left: 0;
						max-width: 120px;

						img {
							margin-top: 30px;
							height: 140px;
						}
					}

					.techno-card__item {
						display: flex;
						align-items: center;

						.techno-card__title {
							max-width: 160px;
						}

						.techno-card__text,
						.blue-button {
							display: none;
						}
					}
				}

				.send-resume-buttom {
					margin: 50px auto;
					width: 279px;
					height: 64px;
				}
			}

			.work-format {
				margin-top: 120px;

				.work-format__image-block-wrapper {
					.work-format__image-item {
						width: 100%;
						.work-format__img {
							height: 412px;
							img {
								height: 100%;
								object-fit: cover;
							}
							.blur {
								width: 100%;
							}
						}
						.work-format__info {
							height: 110px;
						}
					}
				}

				.work-format__info {
					width: 100%;
					height: 112px;
					padding: 25px 20px 30px 30px;

					.work-format__info-text {
						margin-top: 5px;
					}
				}
			}

			.become-part-of-the-ship {
				margin-top: 58px;
			}

			&__questions-block {
				.questions-block__img {
					display: none;
				}

				.blur {
					display: none;
				}

				.career-answer {
					width: 495px;
				}
			}

			.career-page__footer {
				margin-top: 50px;
				padding: 40px 0px;

				.container {
					display: flex;
					flex-direction: column;
				}

				.career-page__footer-info {
					.text-block {
						.career-page__footer-title {
							width: 250px;
							font-size: 1.25rem;
							line-height: 32px;
						}
					}

					.buttons-block {
						margin-top: 40px;

						.buttons-wrapper {
							display: flex;
							justify-content: space-between;
							width: 550px;
						}
					}
				}

				.career-page__footer-form {
					width: 100%;
					margin: 40px 0 0 0;

					.contact-us__form {
						min-width: 100%;

						.contact-us__form-body {
							min-width: 100%;

							input {
								min-width: 340px;
								width: 100%;
								max-width: 100%;
							}

							.contact-us__form-label {
								margin-left: -44%;
							}

							.contact-us__form-label--idea {
								margin-left: -92.5%;
							}
						}
					}

					.contact-us__form-attach {
						margin-left: auto;
					}
				}
			}
		}

		.x-line__x-logo {
			width: 292px;
			right: 50%;
			transform: translateX(50%);
		}

		.x-line__x-logo-substrate {
			right: 0;
			width: 380px;

			&::after {
				border-top: 120px solid transparent;
				border-right: 120px solid $color-white;
				border-bottom: 120px solid transparent;
				left: -115px;
			}
		}

		.contact-us__wrapper {
			margin-top: 46px;
		}
	}

	@media (max-width: 960px) {
		.x-line__x-logo-substrate {
			width: 340px;
		}
	}

	@media (max-width: 860px) {
		.x-line__x-logo-substrate {
			width: 290px;

			&::after {
				left: -115px;
			}
		}
	}

	@media (max-width: 767px) {
		& {
			.career-page__specializations {
				margin-top: 30px;

				.techno-card__wrapper {
					margin-top: 10px;
					grid-template-columns: repeat(4, 1fr);

					.techno-card__box {
						flex-direction: column;
						align-items: center;
						height: auto;
						margin: 0 auto;
						padding: 8px 0;

						.techno-card__img {
							height: 100px;

							& img {
								height: 100%;
								margin: 0;
							}
						}

						.techno-card__item {
							margin-left: 0;
						}

						.techno-card__title {
							display: flex;
							justify-content: center;
							width: 100%;
							text-align: center;
							font-size: 0.75rem;
							line-height: 20px;
						}
					}
				}

				.send-resume-buttom {
					width: 288px;

					.blue-button {
						width: 100%;
						font-size: 1rem;
					}
				}
			}

			.work-format {
				margin-top: 90px;

				.work-format__image-block-wrapper {
					margin-top: 15px;
					display: flex;

					.work-format__image-item {
						width: 100%;
						height: 232px;
						background-size: contain;

						.work-format__info {
							padding: 0.5rem;
							height: 90px;
						}

						.blur {
							width: 100%;
							height: 232px;
						}
					}
				}
			}

			.become-part-of-the-ship {
				margin-top: 60px;

				.main-team__button {
					margin: 25px 0 0 0;
					span {
						font-size: 0.85rem;
					}
				}
			}

			.career-page__questions-block {
				margin-top: 30px;

				.questions-block__body {
					margin-top: 25px;
					width: 100%;

					.career-answer {
						width: 100%;
					}
				}
			}

			.career-page__footer {
				margin-top: 30px;
				padding: 30px 0px;

				.career-page__footer-info {
					width: 100%;
					.text-block {
						.career-page__footer-title {
							font-size: 1rem;
							line-height: 24px;
						}
					}

					.buttons-block {
						margin-top: 35px;

						.h3 {
							width: 100%;
							font-size: 1rem;
							line-height: 24px;
						}

						.buttons-wrapper {
							flex-direction: column;
							width: 100%;
						}
					}
				}

				.career-page__footer-form {
					width: 100%;
					margin: 0;

					.contact-us__form .contact-us__form-body {
						margin-top: 45px;

						input {
							min-width: 100%;
						}

						.contact-us__form-label {
							margin-left: -44%;
						}

						.contact-us__form-label--idea {
							margin-left: -93.5%;
						}
					}
				}
			}
		}
	}
	.career-contact-wrapper {
		.contact-us__body {
			.arrow-button {
				padding: 0 25px;
				.arrow-button__text {
					margin-left: 0;
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.career-page {
		.container {
			.career-page__specializations {
				.techno-card__wrapper {
					grid-template-columns: repeat(3, 1fr);
					.techno-card__box {
						background-color: $color-light-gray;
						width: 100%;
					}
				}
			}

			.work-format {
				.work-format__image-block-wrapper {
					display: flex;
					flex-direction: column;

					.work-format__image-item {
						height: 100%;
						margin-left: 0;

						.work-format__img {
							width: 100%;
							height: 230px;
						}

						&:first-child {
							margin-bottom: 1rem;
						}

						.work-format__image-item:last-child {
							margin-left: 0;
						}
					}
				}
			}
		}

		.career-page__footer {
			.container {
				.career-page__footer-form {
					.contact-us__form .contact-us__form-body {
						.contact-us__form-label {
							margin-left: 0;
							left: 2rem;
						}
					}
				}
				.file__reader {
					max-width: 100%;
					.contact-us__form-attach {
						width: 100%;
						background-position: 170px;
						&.file-name {
							background-position: 10px;
							p {
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 480px) {
	.career-page {
		.container {
			.career-page__specializations {
				.techno-card__wrapper {
					grid-template-columns: 1fr 1fr;
				}
			}
		}

		.career-page__footer {
			.container {
				.contact-us__form .contact-us__form-confirm {
					max-width: 100%;
				}
				.career-page__footer-form .contact-us__form-attach {
					width: 100%;
					background-position: 90px;
				}
			}
		}
	}
}
