@import "../../../assets/style/colors";

.policy {
  margin: 3.5rem 0 10rem;

  &__container {
    background-color: $color-light-gray;
    max-width: 864px;
    margin: 0 auto;
    padding: 2.5rem;
  }

  &__title {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  &__main-title {
    font-size: 1.25rem;
    margin: 1rem 0;
  }

  .shift {
    padding-left: 0.75rem;
  }

  &__text {
    margin-bottom: 1.5rem;

    h3 {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
    }

    p {
      margin-bottom: 0.5rem;
    }

    ul {
      list-style-type: disc;
      padding-left: 1rem;
      margin-bottom: 0.5rem;

      li {
        margin-bottom: 0.25rem;
      }

      & > li > ul {
        list-style: decimal;
        padding-left: 2rem;
        padding-top: 0.25rem;
      }
    }
  }
}
