@import '../../../assets/style/colors';

.specialization-box {
	position: relative;
	display: flex;
	width: 300px;
	height: 115px;
	background-color: $color-light-gray;

	&__icon {
		z-index: 1;
		width: 115px;
		height: 100%;
	}

	&__title {
		display: flex;
		align-items: center;
		margin-left: 15px;
		font-family: 'Commissioner';
		font-weight: bold;
		font-size: 1rem;
		line-height: 24px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $color-main;
	}
	@media (max-width: 1024px) {
		background-color: $color-white;
		width: 100%;
		height: auto;

		&__icon {
			height: 110px;
			width: 100%;
			max-width: 110px;
		}

		&__title {
			font-size: 1.25rem;
		}
	}
	@media (max-width: 767px) {
		flex-direction: column;
		align-items: center;
		margin: 0 auto;
		background-color: $color-white;
		width: 140px;
		height: 152px;

		&__icon {
			width: 100%;
		}

		&__title {
			margin: 10px 0 0 0;
			justify-content: center;
			align-items: center;
			font-size: 0.75rem;
			line-height: 20px;
			letter-spacing: 0.04em;
			min-height: 40px;

			span {
				max-width: 100px;
			}
		}
	}
}
