@import '../../assets/style/colors';

.quick-search {
	.popup-wrapper {
		width: 1315px;

		.send-request {
			width: 100%;
			left: 0;
			display: none;
			&.show {
				display: block;
			}
		}
	}
	.confirm-request__wrapper {
		.popup-wrapper {
			width: 762px;
		}
	}
	&__wrapper {
		margin: 30px 0;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 0;
		}
		.team__filter {
			margin-left: 0;
			width: 100%;
			&-wrapper {
				overflow-y: visible;
			}
			&-box:first-child {
				.team__filter-item {
					columns: 3;
				}
			}
			&-box:last-child {
				padding-bottom: 40px;
				.team__filter-item {
					columns: 3;
					position: relative;
					&::before {
						content: '';
						position: absolute;
						width: 2px;
						height: 100%;
						top: 0;
						left: 365px;
						bottom: 0;
						background-color: #0000001a;
					}
					&::after {
						content: '';
						position: absolute;
						width: 2px;
						height: 100%;
						top: 0;
						right: 430px;
						bottom: 0;
						background-color: #0000001a;
					}
					.filter {
						max-width: 350px;
						margin-bottom: 2rem;
					}
				}
			}
		}

		.technologies-title {
			margin-top: 25px;
		}
		.technologies {
			margin-top: 10px;
			display: flex;
			&-column {
				width: 400px;
				margin-left: 20px;
				padding-right: 10px;
				border-right: 1px solid rgba(0, 0, 0, 0.1);
				&:first-child {
					margin-left: 0px;
				}
				&:last-child {
					border: none;
				}
			}
			&-block {
				margin-bottom: 32px;
			}
			&-subtitle {
				opacity: 0.6;
			}
			&-list {
				display: flex;
				flex-wrap: wrap;
			}

			.oval-button {
				height: 24px;
				margin: 8px 8px 0 0;
				font-size: 0.75rem;
				padding: 3px 8px;
			}
		}

		.send-request {
			position: absolute;
			height: 80px;
			width: 1315px;
			left: -1px;
			border: none;
			background-color: $color-blue;
			span {
				text-transform: uppercase;
				color: $color-white;
			}
		}
	}

	.confirm-request {
		.h3 {
			text-transform: uppercase;
		}
		&__back-btn {
			position: relative;
			margin-top: 7px;
			padding-left: 15px;
			background-color: $color-white;
			border: none;

			&::after {
				position: absolute;
				content: '';
				width: 2px;
				height: 8px;
				top: 6px;
				left: 0;
				background-color: #888a96;
				transform: rotate(45deg);
			}
			&::before {
				position: absolute;
				content: '';
				width: 2px;
				height: 8px;
				top: 11px;
				left: 0;
				background-color: #888a96;
				transform: rotate(-45deg);
			}

			span {
				font-family: 'IBM';
				font-size: 1rem;
				line-height: 140%;
				color: $color-main;
				opacity: 0.7;
			}
		}

		.confirm-request__title {
			margin-top: 35px;
		}
		.timeline {
			margin-top: 25px;
		}
		.confirm-request__form-title {
			margin: 40px 0 15px 0;
		}
	}
}

@media (max-width: 1440px) {
	.quick-search {
		.popup-wrapper {
			width: 100%;
			max-width: 1000px;
			margin: 0 2rem;
			padding: 40px 60px;
			.send-request {
				width: 100%;
				left: 0;
			}
		}

		.team__filter {
			position: static;
			right: 0;
			padding: 0;
			&-box:first-child {
				.team__filter-item {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					height: auto;
				}
			}
			&-box:last-child {
				padding-bottom: 150px;
				.team__filter-item {
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					height: auto;
					&:before,
					&:after {
						content: none;
					}
				}
			}
		}

		&__wrapper .send-request {
			width: 1002px;
			left: -2px;
		}
	}
}

@media (max-width: 1024px) {
	.quick-search {
		.popup-wrapper {
			.send-request {
				max-width: 100%;
			}
		}

		&__wrapper {
			.technologies-column {
				width: 100%;
				padding-right: 0;
			}
		}
	}
}

@media (max-width: 767px) {
	.quick-search {
		&__wrapper {
			margin-bottom: 0;
			margin-top: 10px;
			padding: 1rem;
			height: 65vh;
		}
		.popup-wrapper {
			padding: 20px 0 0;
			min-width: 256px;

			.popup-title {
				padding: 0 20px;
			}
			.send-request {
				width: 100%;
				max-width: 100%;
				height: 60px;
				margin: 0;
				display: block;
				visibility: hidden;
				&.show {
					visibility: visible;
				}
			}
		}
		.confirm-request__back-btn {
			margin-left: 1rem;
		}

		.team__filter {
			max-width: 240px;
			.specializations-item label {
				width: auto;
			}
			&-box:first-child {
				.team__filter-item {
					grid-template-columns: repeat(1, 1fr);
				}
			}
			&-box:last-child {
				padding-bottom: 1rem;
				.team__filter-item {
					grid-template-columns: repeat(1, 1fr);
				}
			}
		}
	}
}
