@import '../../../../assets/style/variables';
@import '../../../../assets/style/colors';
@import '../../../../assets/style/mixins';

.team__list {
	background-color: $color-white;
	position: relative;
	left: 0;
	z-index: 0;
	margin: 0 0.5rem 1rem;
	min-height: 135px;
	padding: 1.25rem 1.5rem 1rem;
	border: 1px solid $color-transparent-gray;
	box-shadow: 0px 2px 4px rgba(55, 59, 79, 0.05), 0px 3px 8px rgba(55, 59, 79, 0.08),
		0px 4px 16px $color-transparent-gray;
	transition: $transition;
	cursor: pointer;

	&.hide {
		display: none;
	}

	&.show {
		display: block;
	}

	&-title {
		border-bottom: 1px solid $color-transparent-gray;
		display: flex;
		justify-content: space-between;
		transition: $transition;

		span {
			display: inline-block;
			transition: $transition;

			&:first-child {
				text-transform: uppercase;
				letter-spacing: 0.03rem;
				padding-bottom: 0.5rem;
				width: 50%;
			}
			&:last-child {
				text-align: right;
			}
		}
	}

	&-button {
		position: absolute;
		height: 100%;
		width: 37px;
		top: 0;
		right: 0;
		bottom: 0;
		transition: $transition;
		z-index: -1;
		background-color: #eef2fc;
		border: none;
		opacity: 0;

		@include bg-image('constructor-arrow.svg');
		background-size: 10px 25px;
	}

	&:hover {
		margin-left: -37px;
		padding: 1.25rem 2.5rem 1rem 1.5rem;

		.team__list-title {
			margin-right: 37px;
		}

		.team__list-button {
			background-color: #d9e1f6;
			opacity: 1;
		}

		.team__skills {
			margin-right: 30px;

			.team__skills-button {
				right: 10%;
			}

			&-item-box {
				& + button {
					right: 37px;
				}
			}
		}
	}
	@media (max-width: 1440px) {
		&:hover {
			margin-left: -20px;
		}
	}
	@media (max-width: 1024px) {
		padding: 1.25rem 1.5rem 1.25rem;
		&-title {
			flex-direction: column;
			padding-bottom: 0.5rem;
			span:first-child {
				padding-bottom: 0;
			}
		}
		&:hover {
			margin-left: -5px;
		}
	}
	@media (max-width: 768px) {
		width: 280px;
		padding: 1.25rem 1rem 1.25rem;
		margin-left: 0.5rem;
		&:hover {
			margin-left: 0.5rem;
			padding: 1.25rem 1rem 1.25rem;
			.team__list-title {
				margin-right: 0;
			}
			.team__skills {
				margin-right: 0;
			}
		}
		&-title {
			span {
				font-size: 16px;
				&:first-child {
					font-size: 14px;
				}
			}
		}
		.team__skills {
			padding-bottom: 37px;
		}
		&-button {
			transform: none;
			right: 0;
			left: 0;
			top: auto;
			bottom: 0;
			width: 100%;
			height: 37px;
			opacity: 1;

			@include bg-image('constructor-arrow-b.svg');
			background-size: 25px 10px;
		}
	}
}
