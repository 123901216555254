@import '../../../assets/style/variables';
@import '../../../assets/style/colors';

.contact-us__form-input {
	position: relative;
	width: 100%;
	max-width: 420px;

	&.error {
		input {
			border-color: $color-red;
		}
	}

	input {
		height: 50px;
		width: 100%;
		width: 100%;
		padding: 20px 15px 5px 15px;
		opacity: 0.78;
		font-family: 'IBM';
		font-size: 1rem;
		line-height: 140%;
		color: $color-purple;
		border: 1px solid rgba(55, 59, 79, 0.3);
		background-color: $color-white;
		transition: $transition;
		cursor: pointer;
		outline: none;

		&.error {
			border-color: $color-red;
		}

		&::placeholder {
			color: rgba(55, 59, 79, 0.3);
		}

		&:disabled {
			color: #959595;
		}

		&:focus,
		&:not([value='']) {
			padding-top: 20px;
			padding-bottom: 7px;

			& + label {
				top: 5px;
				font-size: 0.75rem;
				line-height: 20px;
				color: $color-main;
			}
		}

		&:focus,
		&:hover {
			outline: none;
			opacity: 1;
		}

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
	}
	&.error {
		border-color: $color-red;
	}

	label {
		position: absolute;
		top: 14px;
		left: 17px;
		font-family: 'IBM';
		font-size: 1rem;
		line-height: 140%;
		color: $color-purple;
		pointer-events: none;
		transition: $transition;
	}
}
