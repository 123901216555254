.contact-us-popup-wrapper {
	.contact-us__form-wrapper {
		margin-top: 40px;
	}
	.loading__wrapper {
		height: 60px;
		.loading__close {
			right: 1rem;
		}
		.loading {
			line-height: 60px;
			font-size: 0.75rem;
		}
	}
	@media (max-width: 767px) {
		.contact-us__form-wrapper {
			margin-top: 20px;
		}
	}
}
