@import '../../../assets/style/colors';

.link-box {
	display: flex;
	flex-direction: column;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		top: -16px;
		left: -16px;
		height: calc(100% + 32px);
		width: calc(100% + 32px);
		background-color: transparent;
		transition: background-color 0.3s ease-out;
		z-index: 0;
	}

	&:hover {
		&::before {
			background-color: $color-white;
		}

		img {
			transform: scale(1.05);
		}

		.blur {
			opacity: 0.75;
		}

		.pseudo-link {
			&::before,
			&::after {
				right: -34px;
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
	}
	&__img {
		position: relative;
		z-index: 0;

		.img-wrapper {
			max-height: 176px;
			overflow: hidden;
		}

		img {
			width: 100%;
			object-fit: cover;
			transition: transform 0.3s ease-out;
		}

		.blur {
			top: 8px;
			left: 50%;
			height: 100%;
			width: 96.5%;
			background-repeat: no-repeat;
			transform: translateX(-50%);
			transition: opacity 0.3s ease-out;
			z-index: -1;
		}
	}

	&__title {
		position: relative;
		margin-top: 1.5rem;
		letter-spacing: 0.05rem;
		color: $color-main;
		text-transform: uppercase;
		z-index: 1;
	}

	&__text {
		position: relative;
		flex: 1;
		max-height: 90px;
		margin: 0.5rem 0 2rem;
		color: $color-main;
		overflow: hidden;
		z-index: 1;
	}

	.pseudo-link {
		position: relative;
		margin-right: auto;
		border: none;
		background-color: transparent;
		font-family: 'Commissioner';
		font-size: 1rem;
		font-weight: bold;
		line-height: 24px;
		letter-spacing: 0.04rem;
		color: $color-blue;
		text-transform: uppercase;

		&::before,
		&::after {
			content: '';
			position: absolute;
			right: -26px;
			background-color: $color-blue;
			height: 3px;
			width: 9px;
			transition: right 0.3s ease-out;
		}

		&::before {
			top: 8px;
			transform: rotate(45deg);
		}

		&::after {
			bottom: 8px;
			transform: rotate(-45deg);
		}
	}
	@media (max-width: 1440px) {
		max-width: 304px;

		&__img {
			width: 100%;
			z-index: 1;

			img {
				height: 176px;
			}
		}

		&__text {
			max-height: 100%;
			margin: 0.5rem 0 3.5rem;
		}
	}

	@media (max-width: 1024px) {
		flex-direction: row;
		align-items: flex-start;
		max-width: 100%;
		&__item {
			height: 100%;
		}
		&__img {
			max-width: 250px;
			margin-right: 1.25rem;
			img {
				height: 167px;
			}
		}
		&__title.h2 {
			margin-top: 0;
			font-size: 1rem;
		}
		&__text {
			height: 70px;
		}
		.pseudo-link {
			display: inline-block;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column;
		&__img {
			max-width: 100%;
			margin-right: 0;
			margin-bottom: 1rem;
			img {
				height: 122px;
			}
		}
		&__title.h2 {
			font-size: 0.875rem;
		}
	}
}
