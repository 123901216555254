@import "../../assets/style/colors";
@import "../../assets/style/mixins";
@import "../../assets/style/variables";

.pages-header {
  background-color: rgb(248, 249, 250);
  position: relative;
  z-index: 0;
  min-height: 524px;
  display: flex;
  justify-content: center;
  align-items: center;

  .cluster-link {
    display: inline-block;
    margin-top: 15px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;

    &:hover {
      &::before,
      &::after {
        right: -30px;
        background-color: $color-main;
        transition: $transition;
      }
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      right: -26px;
      background-color: $color-blue;
      height: 3px;
      width: 11px;
      transition: right 0.3s ease-out;
    }

    &::before {
      top: 13px;
      transform: rotate(45deg);
    }

    &::after {
      bottom: 10px;
      transform: rotate(-45deg);
    }
  }

  &__info-block {
    z-index: 3;
    width: 50%;
  }

  &__title {
    font-family: "Commissioner";
    font-weight: bold;
    font-size: 3rem;
    line-height: 56px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $color-main;
  }

  &__text {
    margin-top: 15px;
    font-family: "IBM";
    font-size: 1.5rem;
    line-height: 32px;
    letter-spacing: 1px;
    color: $color-main;
    opacity: 0.7;
  }

  &__x-logo {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 50%;
    height: 100%;
    width: 100%;
    transform: translateX(-50%);

    @include bg-image("backgrounds/X-big-services.svg", center, contain);
  }

  &__image {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -3;
    top: 0;
    right: 0;
    max-width: 50%;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      border-bottom: 200px solid $color-light-gray;
      border-right: 200px solid transparent;
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      border-left: 200px solid $color-light-gray;
      border-bottom: 200px solid transparent;
    }
  }

  .bg-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

@media (max-width: 1440px) {
  .pages-header {
    padding: 0;
    min-height: 422px;

    &__title {
      font-size: 2rem;
      line-height: 40px;
    }

    &__text {
      font-size: 1.25rem;
      line-height: 30px;
      letter-spacing: 1.3px;
    }

    &__x-logo {
      @include bg-image("backgrounds/X-big-services1024.svg", center, contain);
    }
  }
}

@media (max-width: 1024px) {
  .pages-header {
    padding: 20px 0;
    min-height: 344px;

    .pages-header__text {
      margin-top: 20px;
      font-size: 1.1rem;
      line-height: 24px;
      letter-spacing: 0.04em;
    }

    &__x-logo {
      @include bg-image("backgrounds/X-big-services768.png", center, contain);
    }
  }
}

@media (max-width: 767px) {
  .pages-header {
    padding: 60px 0 80px 0;

    &__info-block {
      width: 100%;
    }

    &__x-logo {
      display: none;
    }

    &__image {
      height: 100%;
      width: 100%;
      max-width: none;
      background-position: center;

      &::before,
      &::after {
        display: none;
      }

      &-plug {
        display: none;
      }
    }

    .bg-container::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.85);
    }

    .pages-header__text {
      width: auto;
      max-width: 100%;
      margin-right: 1rem;
      margin-top: 10px;
      font-size: 1rem;
      line-height: 24px;
      letter-spacing: 0.04em;
    }
  }
}
