@import '../../assets/style/colors';

.policy {
	margin: 3.5rem 0 10rem;
	&__container {
		background-color: $color-light-gray;
		max-width: 864px;
		margin: 0 auto;
		padding: 2.5rem;
	}

	&__title {
		text-transform: uppercase;
	}

	&__main-title {
		font-size: 1.25rem;
		margin: 1rem 0;
	}

	&__text {
		margin-bottom: 1rem;
		h3 {
			font-size: 1.25rem;
			margin-bottom: 0.5rem;
		}
	}
}
