@import '../../assets/style/variables';

.techno-card {
	&__box {
		display: flex;
		max-width: 635px;
		width: 100%;
		margin-bottom: 50px;

		.blue-button {
			display: none;
			opacity: 0;
		}
	}
	&__img {
		margin-left: -1.5rem;
		min-width: 140px;
		height: 142px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	&__item {
		width: 389px;
		flex-grow: 1;
		margin-left: 0.5rem;
		overflow: hidden;
	}
	&__title {
		text-transform: uppercase;
	}
	&__text {
		margin-top: 0.5rem;
		max-width: 475px;
		transition: $transition;
		opacity: 1;
	}
}

@media (max-width: 1024px) {
	.techno-card {
		&__item {
			width: auto;
		}
	}
}
