@import '../../../assets/style/variables';
@import '../../../assets/style/colors';

.teams__drop {
	width: 100%;
	max-width: 535px;
	margin-right: -55px;
	position: relative;

	.team {
		&__dropzone {
			height: 100%;
		}

		&__wrapper-drop {
			width: 100%;
			height: 720px;
			margin: 0 1rem 0;
			padding-right: 45px;
			overflow-y: auto;
			&::-webkit-scrollbar {
				width: 0;
				height: 0;
			}

			.drop__wrapper {
				padding-top: 0.5rem;
				height: 100%;
				background-color: $color-light-gray;

				&-devs {
					background-color: $color-light-gray;
					min-height: 100%;
					padding-bottom: 60px;
					box-shadow: 0px -34px 36px -19px rgba(34, 60, 80, 0.3) inset;
				}
			}

			.team__list {
				z-index: 2;

				.team__skills {
					transition: $transition;
				}

				&:hover {
					margin-left: 8px;
					margin-right: -37px;
					padding-left: 60px;

					.team__list-title {
						margin-right: 0;
					}

					.team__skills {
						margin-right: 0;
					}
				}

				&-button {
					right: auto;
					left: 0;
					transform: rotate(180deg);
				}
			}
		}
	}
	.send-request {
		position: absolute;
		height: 60px;
		width: 490px;
		left: 1rem;
		border: none;
		background-color: $color-blue;
		bottom: 0;
		z-index: 10;

		span {
			text-transform: uppercase;
			color: $color-white;
		}
	}
	@media (max-width: 1440px) {
		max-width: 520px;
		margin-right: -20px;
		.team__drop-buttons {
			padding-right: 15px;
		}
		.team__wrapper {
			&-drop {
				padding-right: 30px;

				.team__list {
					&:hover {
						margin-right: -20px;
					}
				}
			}
		}
		.send-request {
			width: calc(100% - 30px);
		}
	}

	@media (max-width: 1024px) {
		margin-left: 0.5rem;
		.team__drop-buttons {
			margin-left: 0;
			padding-right: 30px;
		}
		.team__wrapper {
			&-drop {
				width: 100%;
				max-width: 480px;
				margin: 0;
				padding-right: 15px;

				.team__list {
					&:hover {
						margin-right: -5px;
					}
				}
			}
		}
		.send-request {
			width: calc(100% - 15px);
			max-width: calc(480px - 15px);
			left: 0;
		}
	}
	@media (max-width: 768px) {
		margin: 0;
		width: 100%;
		max-width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		.team__drop-buttons {
			padding: 0 10px;
			width: 100%;
		}
		.team__dropzone {
			width: 100%;
		}
		.team__wrapper {
			width: 100%;
			&-drop {
				width: 100%;
				padding-right: 0;
				max-width: 100%;
				margin-left: 0;
				height: auto;
				overflow-y: visible;
				overflow-x: auto;
				background-color: $color-light-gray;
				.team__list {
					&:hover {
						margin: 0 0.5rem 1rem;
						padding: 1.25rem 1rem 1.25rem;
					}
					&-title {
						padding-top: 37px;
					}
					.team__skills {
						padding-bottom: 0;
					}
				}

				.team__list-button {
					right: 0;
					left: 0;
					top: 0;
					bottom: auto;
				}
				.drop__wrapper {
					width: 100%;
				}
				.drop__wrapper-devs {
					display: flex;
					align-items: flex-start;
					width: 100%;
					padding: 0;
					box-shadow: none;
				}
			}
		}
		.send-request {
			position: static;
			display: block;
			width: 100%;
			max-width: 200px;
			height: 45px;
			margin: 1rem auto;
			span {
				font-size: 0.9rem;
			}
		}
	}
}
