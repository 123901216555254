@import '../../../../assets/style/colors';

.event-body {
	color: $color-purple;
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	.html-renderer {
		width: 100%;
	}

	.event-video {
		width: 100%;
		height: 480px;
		margin-bottom: 3rem;
	}

	.event-text {
		margin-bottom: 2.75rem;
		pre {
			font-family: 'IBM';
			font-weight: normal;
			font-size: 1rem;
			line-height: 1.4;
			white-space: normal;
		}
	}

	.event-key-text {
		font-family: 'Commissioner';
		font-weight: bold;
		font-size: 1.5rem;
		text-transform: uppercase;
		line-height: 1.4;
		letter-spacing: 0.05rem;
		margin-bottom: 2.5rem;
	}
	.event-img {
		margin-bottom: 2.5rem;
		position: relative;
		z-index: 0;
		img {
			width: 100%;
		}
	}
	@media (max-width: 1440px) {
		.event-text {
			margin-bottom: 2rem;
			pre {
				font-size: 0.85rem;
			}
		}
		.event-key-text,
		.event-img {
			margin-bottom: 2rem;
		}

		.event-video {
			margin-bottom: 4rem;
			height: 315px;
		}
	}
	@media (max-width: 1024px) {
		.event-img {
			img {
				max-height: 350px;
				object-fit: cover;
			}
		}
	}
}
