@import '../../assets/style/colors';
@import '../../assets/style/variables';

.slider__wrapper {
	height: auto;
	.popup-wrapper {
		width: 100%;
		max-width: 1160px;
		margin: 0 auto;
		background-color: transparent;
		border: none;

		.popup-close::before,
		.popup-close::after {
			background-color: $color-white;
		}
	}

	.main__slider {
		margin-bottom: 17px;

		.swiper-slide {
			height: auto;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: 660px;
				object-fit: contain;
			}
		}
	}

	.slider__thumbs {
		height: 130px;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.swiper-button-prev,
	.swiper-button-next {
		color: white;
		background-color: rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(16px);
		width: 28px;
		height: 80px;
		transition: $transition;

		&::after,
		&::after {
			font-size: 1rem;
		}

		&:hover,
		&:hover {
			background-color: rgba(0, 0, 0, 0.4);
		}
	}

	.swiper-button-next {
		right: 0;
	}

	.swiper-button-prev {
		left: 0;
	}
	@media (max-width: 1440px) {
		.main__slider {
			.swiper-slide {
				img {
					height: 520px;
				}
			}
		}
		.slider__thumbs {
			height: 100px;
		}
	}

	@media (max-width: 480px) {
		.main__slider {
			.swiper-slide {
				img {
					height: auto;
				}
			}
		}
	}
}
