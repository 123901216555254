@import './colors';
@import './mixins';
@import './variables';
@import './typography';
@import './normalize.css';

* {
	scroll-behavior: smooth;

	&::-webkit-scrollbar {
		width: 6px;
		height: 6px;
		background-color: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #cccccc;
		border-radius: 8px;
	}
}

body {
	font-size: 16px;
	background-color: $color-white;
	color: $color-main;
	min-width: 320px;
	font-family: Arial, Helvetica, sans-serif;
}

.h1 {
	font-family: 'Commissioner', sans-serif;
	font-size: 3rem;
	font-weight: bold;
	line-height: 56px;
	letter-spacing: 0.11rem;
}

.h2 {
	font-family: 'Commissioner', sans-serif;
	font-size: 1.5rem;
	font-weight: bold;
	line-height: 32px;
}

.h3 {
	font-family: 'Commissioner', sans-serif;
	font-size: 1rem;
	font-weight: bold;
	line-height: 24px;
}

.text1 {
	font-family: 'IBM', sans-serif;
	font-size: 1.5rem;
	font-weight: normal;
	line-height: 32px;
}

.text2 {
	font-size: 1rem;
	font-family: 'IBM', sans-serif;
	font-weight: normal;
	line-height: 22px;
}

.text3 {
	font-size: 0.75rem;
	font-family: 'IBM', sans-serif;
	font-weight: normal;
	line-height: 20px;
}

a {
	color: $color-blue;
	text-decoration: none;
	transition: $transition;
	cursor: pointer;
}

a:focus,
a:hover {
	color: $color-main;
	outline: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
	list-style-type: none;
}

.container {
	width: 100%;
	max-width: 1312px;
	margin: 0 auto;
	&-fluid {
		width: 100%;
		max-width: 100%;
	}
}

.page-wrapper {
	max-width: 1300px;
	margin: auto;
}

button {
	cursor: pointer;
}

.blur {
	position: absolute;
	-moz-filter: blur(15px);
	-o-filter: blur(15px);
	-ms-filter: blur(15px);
	-webkit-filter: blur(15px);
	filter: blur(15px);
	opacity: 0.6;
	transition: $transition;
	width: 98%;
	height: 100%;
	top: 5px;
	right: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: -1;
}

@media (max-width: 1440px) {
	.container {
		max-width: 945px;
	}
	.page-wrapper {
		max-width: 945px;
	}
	.h1 {
		font-size: 2rem;
		line-height: 40px;
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}

	.h2 {
		font-size: 1rem;
		line-height: 24px;
	}

	.text1 {
		font-size: 1.25rem;
		line-height: 28px;
	}

	.text2 {
		font-size: 0.9rem;
		line-height: 140%;
	}

	.text3 {
		font-size: 0.75rem;
		line-height: 20px;
	}
}

@media (max-width: 1024px) {
	.container {
		max-width: 100%;
		padding: 0 1.75rem;
	}
	.page-wrapper {
		max-width: 712px;
	}

	.h2 {
		font-size: 1.25rem;
		line-height: 1.3;
	}

	.h3 {
		font-size: 0.875rem;
	}
}

@media (max-width: 767px) {
	.container {
		max-width: 100%;
		padding: 0 1rem;
	}
	.page-wrapper {
		max-width: 100%;
	}
	.h1 {
		font-size: 1.5rem;
		line-height: 32px;
	}
	.h3 {
		font-size: 0.75rem;
	}

	.text2 {
		font-size: 14px;
		line-height: 140%;
	}
}
