@import '../../assets/style/colors';
@import '../../assets/style/mixins';

.x-line {
	&__block {
		position: relative;
		background-color: $color-main;

		.page-wrapper {
			height: 240px;
			display: flex;
			align-items: center;
		}

		&-title {
			width: 560px;
			z-index: 1;
			font-family: 'Commissioner';
			font-weight: bold;
			font-size: 2rem;
			line-height: 40px;
			letter-spacing: 0.04em;
			text-transform: uppercase;
			color: $color-white;
		}

		.main-team__button {
			z-index: 1;
			margin-left: 210px;
		}
	}
	&__x-logo {
		position: absolute;
		top: 0;
		right: 226px;
		width: 100%;
		height: 100%;

		@include bg-image('backgrounds/X-small-services.svg', right, contain);

		&-substrate {
			position: absolute;
			top: 0;
			right: 0;
			width: 250px;
			height: 100%;
			background-color: $color-white;
			opacity: 0.7;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: -140px;
				width: 0;
				height: 100%;
				border-top: 130px solid transparent;
				border-right: 145px solid $color-white;
				border-bottom: 120px solid transparent;
			}
		}
	}

	@media (max-width: 1440px) {
		& {
			&__block {
				&-title {
					margin-left: 25px;
				}

				.main-team__button {
					margin-left: 280px;
					white-space: nowrap;
				}
			}
			&__x-logo {
				right: 360px;
				&-substrate {
					width: 380px;
				}
			}
		}
	}

	@media (max-width: 1024px) {
		& {
			&__block {
				&-title {
					margin-left: 0px;
					font-size: 1.5rem;
				}

				.main-team__button {
					margin-left: 200px;
					span {
						white-space: nowrap;
						line-height: 25px;
					}
				}
			}
			&__x-logo {
				right: 290px;

				&-substrate {
					display: block;
					right: -80px;
				}
			}
		}
	}

	@media (max-width: 767px) {
		& {
			&__block {
				.page-wrapper {
					flex-direction: column;
					height: 192px;
				}
				&-title {
					max-width: 290px;
					margin: 16px 0 0 0px;
					text-align: center;
				}
			}
			&__x-logo {
				display: none;
				&-substrate {
					display: none;
				}
			}
		}
	}
}
