@import '../../assets/style/colors';

.brands {
	height: 80px;
	display: flex;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.8);
	position: relative;

	&__blur {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		height: 80px;
		width: 100%;
		-moz-backdrop-filter: blur(7px);
		-o-backdrop-filter: blur(7px);
		-ms-backdrop-filter: blur(7px);
		-webkit-backdrop-filter: blur(7px);
		backdrop-filter: blur(7px);
	}

	.container {
		height: 100%;
		display: flex;
		align-items: center;
	}

	&__scroll-wrapper {
		margin-right: -5rem;
	}

	&__wrapper {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&__title {
		font-family: 'IBM';
		font-weight: bold;
		font-size: 0.8rem;
		color: $color-blue;
		text-transform: uppercase;
		max-width: 180px;
		line-height: 1.35;
		letter-spacing: 0.1rem;
		opacity: 0.7;
	}

	&__box {
		display: flex;
		justify-content: flex-end;
	}

	&__list {
		display: grid;
		grid-auto-flow: column;
		column-gap: 110px;
	}

	&__img {
		align-self: center;
	}

	@media (max-width: 1500px) {
		&__scroll-wrapper {
			margin-right: 0;
		}
	}

	@media (max-width: 1440px) {
		& {
			&__wrapper {
				display: flex;
				justify-content: space-between;
			}

			&__list {
				column-gap: 40px;
			}

			&__img {
				width: 7rem;
			}
		}
	}

	@media (max-width: 1024px) {
		& {
			&__wrapper {
				display: flex;
				justify-content: space-between;
			}

			&__title {
				min-width: 170px;
			}

			&__list {
				column-gap: 32px;
			}

			&__img {
				width: auto;
				max-width: 110px;
				max-height: 30px;

				&.extra-sm {
					max-width: 70px;
				}
			}
		}
	}
}

@media (max-width: 767px) {
	.brands {
		height: auto;
		overflow: hidden;

		&__blur {
			height: 100%;
			position: static;
		}

		&__wrapper {
			flex-wrap: wrap;
			margin: 16px 0;
		}

		&__title {
			flex-grow: 1;
			min-width: 100%;
		}

		&__scroll-wrapper {
			position: relative;
			width: 100vw;
			left: -17px;
		}

		&__scroll-container {
			padding: 0 16px 12px;
			overflow-x: scroll;
			&::-webkit-scrollbar {
				height: 0;
			}
		}

		&__box {
			justify-content: flex-start;
		}

		&__list {
			margin: 25px 0 0 0;
			max-width: 100%;

			&::after {
				content: '';
				display: block;
				width: 1px;
			}
		}
	}
}
