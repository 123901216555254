@mixin max-width($media) {
	@media screen and (max-width: ($media - 1px)) {
		@content;
	}
}

@mixin bg-image($url, $position: center, $size: cover) {
	background-image: url('../images/' + $url);
	background-position: $position;
	background-size: $size;
	background-repeat: no-repeat;
}

@mixin spin {
	animation: spin 1.25s linear infinite;

	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
}

@mixin fadeIn {
	animation: fade-in 0.2s ease-out backwards;

	@keyframes fade-in {
		0% {
			opacity: 0;
		}

		100% {
			opacity: 1;
		}
	}
}

@mixin slideIn {
	animation: slide-in 0.2s ease-out backwards;

	@keyframes slide-in {
		0% {
			transform: translateY(16px);
		}

		100% {
			transform: translateY(0);
		}
	}
}
