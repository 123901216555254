@import '../../../assets/style/colors';
@import '../../../assets/style/variables';

.main-team__button {
	text-transform: uppercase;
	color: $color-main;
	border: 1px solid rgba(55, 59, 79, 0.4);
	padding: 0.9rem 4.35rem 0.9rem 3rem;
	letter-spacing: 0.04rem;
	position: relative;
	background-color: $color-white;
	transition: $transition;
	box-shadow: 0px 2px 4px rgba(73, 67, 98, 0.03), 0px 3px 8px rgba(73, 67, 98, 0.05),
		0px 4px 16px rgba(73, 67, 98, 0.15);
	&::before,
	&::after {
		content: '';
		position: absolute;
		background-color: $color-blue;
		height: 3px;
		width: 9px;
		right: 45px;
		transition: $transition;
	}
	&::before {
		top: 23px;
		transform: rotate(45deg);
	}
	&::after {
		bottom: 22px;
		transform: rotate(-45deg);
	}
	&:hover {
		box-shadow: none;
		background-color: $color-light-gray;
		&::before,
		&::after {
			right: 35px;
		}
	}
}
