@import '../../assets/style/colors';
@import '../../assets/style/mixins';

.popup {
	&-plug {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 100;
		backdrop-filter: blur(16px);
		background-color: rgba(0, 0, 0, 0.5);
		border: none;
		cursor: default;
		display: flex;
		justify-content: center;
		align-items: center;

		@include fadeIn;
	}

	&-wrapper {
		position: relative;
		max-height: 100%;
		max-width: 100%;
		padding: 40px 60px 40px 60px;
		background-color: $color-white;
		border: 1px solid rgba(55, 59, 79, 0.3);
		overflow-y: auto;

		@include slideIn;

		&::-webkit-scrollbar {
			width: 2px;
		}

		.contact-us__form-checkbox-label-link {
			color: rgba(55, 59, 79, 0.6);
		}
	}

	&-title {
		padding-right: 25px;
		font-family: 'Commissioner';
		font-size: 1.5rem;
		line-height: 32px;
		font-weight: bold;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		color: $color-main;
	}

	&-close {
		position: absolute;
		top: 25px;
		right: 20px;
		width: 15px;
		height: 15px;
		border: none;
		background-color: inherit;
		z-index: 2;

		&::before {
			position: absolute;
			content: '';
			top: -2px;
			left: 6px;
			height: 20px;
			width: 1px;
			background-color: #4c4f61;
			transform: rotate(45deg);
		}

		&::after {
			position: absolute;
			content: '';
			top: -2px;
			left: 6px;
			height: 20px;
			width: 1px;
			background-color: #4c4f61;
			transform: rotate(-45deg);
		}
	}
	@media (max-width: 767px) {
		&-wrapper {
			padding: 0.75rem 1rem 1rem;
			margin: 1rem;
			overflow-y: auto;
			.popup-close {
				top: 7px;
				right: 5px;
			}
			.popup-title {
				font-size: 1rem;
			}

			.contact-us__form-body {
				margin-top: 0;
			}
		}
	}

	@media (max-width: 450px) {
		.popup-wrapper {
			padding: 16px;

			.contact-us__form {
				.arrow-button__wrapper {
					justify-content: flex-start;
				}
			}
		}
	}
}
