@import "../../assets/style/colors";
@import "../../assets/style/variables";
@import "../../assets/style/mixins";

.header {
  position: fixed;
  top: 0;
  z-index: 99;
  width: 100%;
  padding: 0 80px 0 71px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #373b4f1c;
  background-color: $color-white;
  &__filter {
    display: none;
  }
  &__burger {
    display: none;
  }

  .open__burger {
    display: none;
  }

  &-container {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    margin: 19px 0;

    a {
      display: flex;
    }

    // .logo-suffix {
    //   position: relative;
    //   display: flex;

    //   img {
    //     width: 180px;
    //   }

    //   span {
    //     position: absolute;
    //     top: 50%;
    //     right: -6px;
    //     font-family: "Comissioner", sans-serif;
    //     font-size: 14px;
    //     font-weight: bold;
    //     color: #30323b;
    //     text-shadow: 1px 1px 1px #00000033;
    //     white-space: nowrap;
    //     transform: translate(0, -50%);
    //   }
    // }
  }

  &__nav {
    display: flex;
    align-items: center;

    img {
      display: none;
    }

    .close-burger {
      display: none;
    }

    &-wrapper {
      display: flex;
    }

    &-item {
      margin-left: 3.9rem;
      white-space: nowrap;
    }

    &-link {
      opacity: 0.7;
      color: $color-main;
      position: relative;
      transition: $transition;

      &:hover {
        opacity: 1;

        &::before {
          width: 100%;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -0.45rem;
        background-color: $color-blue;
        width: 0%;
        height: 2px;
        transition: $transition;
      }
    }

    &-link-active {
      opacity: 1;

      &::before {
        width: 100%;
      }
    }

    .header__button {
      display: none;
    }
  }

  &__button {
    color: $color-blue;
    background-color: $color-white;
    border: 1px solid rgba(55, 59, 79, 0.3);
    text-transform: uppercase;
    padding: 0.95rem 3.3rem;
    margin-left: 5rem;
    box-shadow: 0px 4px 16px rgba(73, 67, 98, 0.1);
    transition: $transition;
    white-space: nowrap;

    &:hover {
      border: 1px solid rgba(55, 59, 79, 0.4);
      background-color: #f7f7f7;
    }
  }
}

@media (max-width: 1480px) {
  .header__button {
    margin-left: 2.5rem;
  }
}

@media (max-width: 1440px) {
  .header {
    padding: 0 40px;

    &__logo {
      margin: 16px 0 10px 0;
    }

    &-container {
      flex-wrap: wrap;
      max-width: 815px;

      .header__logo {
        flex-grow: 1;
      }
    }

    &__nav {
      margin-bottom: 5px;
    }

    &__nav-item {
      margin-left: 45px;
      white-space: nowrap;

      &:first-child {
        margin-left: 0;
      }
    }

    &__button {
      margin-left: 35px;
      padding: 0.95rem 3rem;
    }
  }
}

@media (max-width: 1024px) {
  .header {
    width: 100%;
    height: 48px;
    padding: 0 1.75rem;
    margin: 0 auto;

    &__logo {
      margin: 0;
    }

    .open__burger {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border: none;
      background-color: transparent;
      width: 24px;
      height: 24px;
      transition: $transition;
      &.active {
        span {
          background-color: transparent;
          transform: rotate(-270deg);
          &::after {
            width: 24px;
            height: 3px;
            bottom: 0;
            right: -5px;
            transform: rotate(45deg);
          }

          &::before {
            width: 24px;
            height: 3px;
            top: 0;
            right: -5px;
            transform: rotate(-45deg);
          }
        }
      }

      span {
        display: block;
        position: relative;
        background-color: $color-main;
        width: 16px;
        height: 3px;
        transition: $transition;

        &::after {
          content: "";
          position: absolute;
          width: 24px;
          height: 3px;
          bottom: 8px;
          right: 0;
          background-color: $color-main;
          transition: $transition;
        }

        &::before {
          content: "";
          position: absolute;
          width: 24px;
          height: 3px;
          top: 8px;
          right: 0;
          background-color: $color-main;
          transition: $transition;
        }
      }
    }
  }

  .header__logo img {
    max-width: 200px;
  }

  .burger-menu__wrapper {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 48px;
    left: 0;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(16px);
    backdrop-filter: blur(16px);
    overflow-y: auto;

    @include fadeIn;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .burger-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    width: 100%;
  }

  .header__nav {
    display: none;
    padding: 2rem 28px;

    img {
      max-width: 200px;
      display: inline-block;
    }

    &.active-burger {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      background-color: $color-white;

      .header__nav-wrapper {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: 100%;

        .header__nav-item {
          width: 100%;
          margin: 24px 0 0 0;

          .text2 {
            font-family: "Commissioner";
            font-weight: bold;
            font-size: 1rem;
            line-height: 24px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
          }

          .header__nav-link {
            display: flex;
            align-items: center;
            height: 36px;

            &::before {
              content: "";
              position: absolute;
              bottom: 18px;
              left: 0;
              background-color: $color-blue;
              width: 4px;
              height: 0%;
              transition: $transition;
            }

            &-active {
              opacity: 1;

              &::before {
                left: -28px;
                bottom: 0;
                height: 100%;
              }
            }
          }
        }
      }
      .header__button {
        display: block;
        align-self: center;
        width: 100%;
        margin: 3.75rem 0 0;
        font-size: 1rem;
      }
    }
  }

  .header__button {
    display: none;
  }
}

@media (max-width: 767px) {
  .header {
    padding: 0 0.5rem;
    height: 32px;
    &-container {
      flex-wrap: nowrap;
      height: 100%;
    }
    &__logo {
      a {
        display: flex;
        align-items: center;
      }
      img {
        max-width: 150px;
      }
    }
    &__nav {
      &.active-burger {
        width: 280px;
        height: calc(100% - 32px);
        justify-content: flex-end;
        margin-left: auto;
        padding: 1rem 0 0;
        .header__nav-wrapper {
          .header__nav-item {
            margin-top: 13px;
            .header__nav-link {
              font-size: 0.8rem;
              padding: 0 28px;
              &-active {
                background-color: #5d83e41a;
                &::before {
                  left: 0;
                }
              }
            }
          }
        }
        .header__button {
          margin-bottom: 1rem;
          margin-top: 1rem;
          max-width: 260px;
        }
      }
    }

    &__nav-wrapper {
      padding-bottom: 1rem;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 2px;
      }
    }

    .burger-menu__wrapper {
      top: 32px;
    }

    &__filter {
      display: block;
      background-color: #e4e5e7;
      border: none;
      cursor: pointer;
      padding: 0 0.9rem;
      height: 100%;
      color: $color-blue;
      font-weight: 400;
      font-size: 12px;
      &.text2 {
        font-size: 12px;
      }
    }
  }

  .header__button {
    display: none;
  }
}
