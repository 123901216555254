@import '../../../assets/style/variables';
@import '../../../assets/style/colors';

.arrow-button {
	display: flex;
	align-items: center;
	position: relative;
	height: 56px;
	min-width: 180px;
	margin-right: 45px;
	padding: 0 35px;
	border: none;
	transition: $transition;

	&:hover {
		& + .arrow-button__tail {
			left: 8px;
		}

		& .arrow-button__substrate {
			right: -22px;
			opacity: 0.4;
		}

		.arrow-wrapper .arrow {
			right: -8px;
		}
	}

	&__wrapper {
		display: flex;
		min-width: 100%;

		&.left {
			justify-content: flex-start;
		}

		&.right {
			justify-content: flex-end;
		}
	}

	&__text {
		z-index: 2;
		font-weight: bold;
		font-size: 1rem;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}

	&::before {
		content: '';
		position: absolute;
		border-top: 28px solid transparent;
		border-right: 0 solid transparent;
		border-bottom: 27px solid transparent;
		right: -27px;
		height: 100%;
		width: 27px;
		transition: $transition;
	}

	.arrow-wrapper {
		position: absolute;
		right: -45px;
		height: 100%;
		width: 45px;
		transition: $transition;
		clip-path: polygon(39% 0, 100% 50%, 37% 100%, 0 100%, 28px 50%, 0 0);
		background-color: transparent;
		.arrow {
			position: absolute;
			right: 0;
			height: 100%;
			width: 38px;
			clip-path: polygon(10px 0, 100% 50%, 10px 100%, 0 100%, 28px 50%, 0 0);
			transition: $transition;
		}
	}

	&.white {
		background-color: $color-white;
		color: $color-blue;
		padding: 0 42px;
		box-shadow: 0px 8.4px 16.8px $color-main;

		&:hover {
			box-shadow: none;

			&::before {
				border-left: 27px solid $color-white;
			}

			.arrow {
				box-shadow: none;
			}
			.arrow-wrapper {
				background-color: rgba(255, 255, 255, 0.3);
				clip-path: polygon(56% 0%, 117% 50%, 56% 100%, 0% 100%, 28px 50%, 0% 0%);
			}
		}

		&::before {
			border-left: 27px solid $color-white;
		}

		.arrow {
			background-color: $color-white;
			box-shadow: 0px 8.4px 16.8px $color-main;
		}
	}

	&.blue {
		background-color: $color-blue;
		color: $color-white;
		box-shadow: 0px 8.4px 16.8px rgba(39, 135, 245, 0.24), 0px 4.28px 8.4px rgba(39, 135, 245, 0.16),
			0px 1.15px 2.8px rgba(39, 135, 245, 0.2);

		&:hover {
			background-color: $color-blue-focus;
			box-shadow: none;

			&::before {
				border-left: 27px solid $color-blue-focus;
			}

			.arrow {
				background-color: $color-blue-focus;
				box-shadow: none;
			}

			.arrow-wrapper {
				clip-path: polygon(56% 0%, 117% 50%, 56% 100%, 0% 100%, 28px 50%, 0% 0%);
				background-color: rgba(93, 131, 228, 0.3);
			}
		}

		&::before {
			border-left: 27px solid $color-blue;
		}

		.arrow {
			background-color: $color-blue;
			box-shadow: 0px 8.4px 16.8px rgba(39, 135, 245, 0.24),
				0px 4.28px 8.4px rgba(39, 135, 245, 0.16), 0px 1.15px 2.8px rgba(39, 135, 245, 0.2);
		}
	}
}
