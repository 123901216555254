@font-face {
    font-family: "Commissioner";
    src: url("./../fonts/Commissioner/Commissioner-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "Commissioner";
    src: url("./../fonts/Commissioner/Commissioner-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "IBM";
    src: url("./../fonts/IBMPlexMono/IBMPlexMono-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "IBM";
    src: url("./../fonts/IBMPlexMono/IBMPlexMono-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
}
