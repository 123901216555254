@import '../../../../assets/style/colors';

.event__gallery {
	margin-top: 2.75rem;

	.h2 {
		text-transform: uppercase;
		letter-spacing: 0.05rem;
		margin-bottom: 0.85rem;
	}

	&-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 28px;
	}

	&-item {
		position: relative;
		z-index: 0;
		background-color: transparent;
		padding: 0;
		border: none;
		width: calc((100% / 3) - 19px);
		height: 160px;

		img {
			object-fit: cover;
			width: 100%;
			height: 100%;
		}

		.blur {
			width: 95%;
			height: 95%;
			top: 3px;
			right: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}
		&.wide {
			width: calc((100% / 2) - 14px);
			height: 240px;
		}
	}

	&-button {
		font-family: 'IBM';
		font-size: 1rem;
		font-weight: bold;
		color: $color-blue;
		background-color: transparent;
		border: none;
		position: relative;
		margin-top: 2.5rem;

		&::before,
		&::after {
			content: '';
			position: absolute;
			width: 10px;
			height: 3px;
			background-color: $color-blue;
			top: 50%;
			transform: translateY(-50%);
		}

		&::before {
			transform: rotate(45deg);
			right: -1.7rem;
		}

		&::after {
			transform: rotate(-45deg);
			right: -2rem;
		}

		&.close::before {
			transform: rotate(-45deg);
		}

		&.close::after {
			transform: rotate(45deg);
		}
	}

	@media (max-width: 1440px) {
		.h2 {
			font-size: 1.5rem;
			margin-bottom: 1rem;
		}

		&-wrapper {
			gap: 20px;
		}

		&-item {
			height: 120px;
			width: calc((100% / 3) - 14px);
			&.wide {
				width: calc((100% / 2) - 10px);
				height: 175px;
			}
		}
		&-button {
			margin-top: 1.25rem;
		}
	}

	@media (max-width: 480px) {
		&-wrapper {
			flex-wrap: nowrap;
			overflow-x: auto;
			padding-bottom: 2rem;
			&::-webkit-scrollbar {
				height: 0;
			}
		}
		&-item {
			width: 100%;
			&.wide {
				width: 100%;
				height: 120px;
			}
			img {
				width: auto;
			}
		}
	}
}
